const requireContext = require.context('./requestBooking', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  startDate: null,
  duration: 1,
  rentPeriod: null,
  userInput: '',
  loading: false,
  startTime: 0,
  phone: '',
  customAgreement: {},

  //data modal
  disabledDates: [],
  moveDate: null,
  localDuration: 1,
  periodName: null,
  localPeriodId: null,
  localPhone: '',
});

export const mutations = {
  SET_DISABLE_DATES(state, payload) {
    state.disabledDates = payload;
  },
  SET_LOCAL_PHONE(state, payload) {
    state.localPhone = payload;
  },
  SET_PERIOD_NAME(state, payload) {
    state.periodName = payload;
  },
  SET_LOCAL_PERIOD_ID(state, payload) {
    state.localPeriodId = payload;
  },
  SET_LOCAL_DURATION(state, payload) {
    state.localDuration = payload;
  },
  SET_CUSTOM_AGREEMENT(state, payload) {
    state.customAgreement = payload;
  },

  SET_MOVE_DATE(state, payload) {
    state.moveDate = payload;
  },

  SET_START_TIME(state, payload) {
    state.startTime = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_START_DATE(state, payload) {
    state.startDate = payload;
  },
  SET_DURATION(state, payload) {
    state.duration = payload;
  },
  SET_USER_INPUT(state, payload) {
    state.userInput = payload;
  },
  SET_RENT_PERIOD(state, payload) {
    state.rentPeriod = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_DISABLE_DATES', []);
    commit('SET_MOVE_DATE', null);
    commit('SET_LOCAL_DURATION', 1);
    commit('SET_PERIOD_NAME', null);
    commit('SET_LOCAL_PERIOD_ID', null);
    commit('SET_LOCAL_PHONE', '');
  },

  async checkCashbackAvailable() {
    try {
      const { data } = await this.$http.get('/api/v2/booking_request/check_cashback_available');
      return data.data;
    } catch (e) {
      throw e;
    }
  },

  async submit({ commit, state }, projectUuid) {
    try {
      commit('SET_LOADING', true);
      let moveTime = null;
      if (state.rentPeriod === 5) {
        moveTime = state.startTime + '.00';
      }
      let postData = {
        startDate: this.$date.format(state.startDate, 'dd-MM-yyyy'),
        duration: state.duration,
        rentPeriodId: state.rentPeriod,
        projectUuid: projectUuid,
        userInput: state.userInput,
        move_time: moveTime,
        phone: state.phone,
      };
      if (state.promo.promoDetail) {
        postData.promo_uuid = state.promo.promoDetail.uuid;
      }
      if (state.customAgreement && state.customAgreement.uuid) {
        postData.custom_agreement_uuid = state.customAgreement.uuid;
      }
      // if (!rootState.global.loggedIn) {
      //   postData.registerData = {
      //     name: rootState.signUp.name,
      //     email: rootState.signUp.email,
      //     password: rootState.signUp.password,
      //     phone: rootState.signUp.phone,
      //   };
      // }
      const { data } = await this.$http.post('/api/v2/booking_request/initiate', postData);
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
