export const state = () => ({
  propertyTypes: [],
  rentTypes: [],
  rentPeriods: [],
  propertyType: '',
  rentType: {},
  propertyPartner: [],
  tenureTypes: [],
  propertyTypeEnum: {
    HOUSE: 'Rumah',
    APARTMENT: 'Apartemen',
  },
  listingTypeEnum: {
    SECONDARY: 'SECONDARY',
    PRIMARY: 'PRIMARY',
  },
  listingTypes: [
    {
      id: 2,
      name: 'Properti Dijual',
    },
    {
      id: 1,
      name: 'Proyek Terbaru',
    },
  ],
});

export const getters = {
  propertyTypesForFilter(state, getters, rootState) {
    let listingType = rootState.v2.search.selectedListingType;
    let list = JSON.parse(JSON.stringify(state.propertyTypes));
    if (listingType && listingType.id !== 1) {
      list = list.filter((propertyType) => {
        return propertyType.id !== -1;
      });
    }
    return list;
  },
  propertyTypesForMortgage(state) {
    let list = JSON.parse(JSON.stringify(state.propertyTypes));
    list = list.filter((propertyType) => {
      return propertyType.id !== null && propertyType.id !== -1;
    });
    return list;
  },
  // eslint-disable-next-line no-unused-vars
  propertyTypesByListingType(state, getters, rootState) {
    // if (rootState.v2.search.selectedListingType.id === 1) {
    //   return [
    //     {
    //       id: 1,
    //       name: 'Rumah',
    //     },
    //     {
    //       id: 2,
    //       name: 'Apartemen',
    //     },
    //   ];
    // } else {
    let list = JSON.parse(JSON.stringify(state.propertyTypes));
    list = list.filter((propertyType) => {
      return propertyType.id !== null && propertyType.id !== -1;
    });
    return list;
    // }
  },
};

export const mutations = {
  set_property_types(state, payload) {
    state.propertyTypes = payload;
  },
  set_property_type(state, payload) {
    state.propertyType = payload;
  },
  set_rent_types(state, payload) {
    state.rentTypes = payload;
  },
  set_rent_type(state, payload) {
    state.rentType = payload;
  },
  set_rent_periods(state, payload) {
    state.rentPeriods = payload;
  },
  set_property_partner(state, payload) {
    state.propertyPartner = payload;
  },
  set_tenure_types(state, payload) {
    if (payload)
      payload.unshift({
        id: null,
        name: 'general.filter.allTenures',
      });
    state.tenureTypes = payload;
  },
};

export const actions = {
  async getPropertyTypes({ commit }) {
    await this.$http.get('/api/master/property-types').then((response) => {
      let list = response.data.data;
      let newList = [
        { id: null, name: 'Semua Tipe Properti' },
        // { id: -1, name: 'Rumah Subsidi', originalId: 1 },
      ];
      if (list) {
        for (let item of list) {
          newList.push({ id: item.id, name: item.name });
        }
      }
      commit('set_property_types', newList);
    });
  },

  // eslint-disable-next-line no-unused-vars
  async getPropertyTypesHeader({ commit }, { category_id: categoryId }) {
    let response = await this.$http.get('/api/v2/master/property-types', {
      params: {
        category_id: categoryId,
      },
    });
    return response.data.data;
  },
  async getPropertyTypeByName({ state }, propertyTypeName) {
    const propertyType = await state.propertyTypes.find(function (item) {
      return item
        ? item.name.toLowerCase() === propertyTypeName.toLowerCase().replace(/-/g, ' ')
        : false;
    });
    if (propertyType) return JSON.parse(JSON.stringify(propertyType));
  },
  async getPropertyTypeById({ state, dispatch }, propertyTypeId) {
    await dispatch('getPropertyTypes');
    const propertyType = await state.propertyTypes.find(function (item) {
      return item ? item.id === parseInt(propertyTypeId) : false;
    });
    if (propertyType) return JSON.parse(JSON.stringify(propertyType));
  },
  async getRentTypes({ commit }) {
    await this.$http.get('/api/v2/master/rent-types').then((result) => {
      commit('set_rent_types', result.data.data);
    });
  },

  async getRentTypesByCategory({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/rent-types', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then((response) => {
        commit('set_rent_types', response.data.data);
      });
  },

  async getRentTypeById({ commit }, id) {
    await this.$http.get('/api/v2/master/' + id + '/rent-type').then((response) => {
      commit('set_rent_type', response.data.data);
    });
  },
  async getRentPeriods({ commit }) {
    await this.$http.get('/api/v2/master/rent-periods').then((result) => {
      commit('set_rent_periods', result.data.data);
    });
  },

  async getRentPeriodsByCategory({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/rent-periods', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then((response) => {
        commit('set_rent_periods', response.data.data);
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async getCitiesByProvinceId({}, province_id) {
    let response = await this.$http.get('/api/v2/regional/get-cities-by-province', {
      params: {
        province_id: province_id,
      },
    });

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getVillagesByDistrictId({}, district_id) {
    let response = await this.$http.get('/api/v2/regional/get-villages-by-district', {
      params: {
        district_id: district_id,
      },
    });

    return response.data.data;
  },
  async getProjectPartner({ commit }) {
    let response = await this.$http.get('/api/v2/master/project-partner');

    commit('set_project_partner', response.data.data);
  },
  async getPropertyPartner({ commit }) {
    let response = await this.$http.get('/api/v2/master/property-partner');

    commit('set_property_partner', response.data.data);
  },
  async getTenureTypes({ commit }) {
    let response = await this.$http.get('/api/v2/master/tenure-types');

    commit('set_tenure_types', response.data.data);
  },
};
