export const state = () => ({
  detail: {},
  imageIndex: null,
  project: {},
  categories: [],
  selectedCategory: {},
  agentUuid: null,
});

export const getters = {
  galleries(state) {
    if (Object.keys(state.detail).length > 0) {
      let clonedPhotos = JSON.parse(JSON.stringify(state.detail.photos));
      clonedPhotos.sort(function (a, b) {
        if (a.is_primary_photo === true) return -1;
        else if (b.is_primary_photo === true) return 1;
        else return 0;
      });

      let photos = clonedPhotos.map(function (photo) {
        return photo.url;
      });

      return photos ? photos : [];
    }
    return [];
  },
  facilities(state) {
    return state.detail && state.detail.facilities ? state.detail.facilities.data : [];
  },
  units(state) {
    if (state.detail && state.detail.units) {
      let newUnits = [];
      let units = state.detail.units.data;
      for (let i = 0; i < units.length; i++) {
        if (units[i].unit_category_id === state.selectedCategory.id) {
          units[i].unit_type_name = state.detail.name;
          units[i].photo = state.detail.photo;
          units[i].electricity_capacity = state.detail.electricity_capacity;
          units[i].furnishing_status = state.detail.furnishing_status;
          units[i].property_type = state.project.property_type;
          newUnits.push(units[i]);
        }
      }
      console.log('GET CURRENT UNITS: ', newUnits);
      return newUnits;
    }
    return [];
  },
  primaryPhoto(state) {
    if (state.detail && state.detail.photos && state.detail.photos.data) {
      let photos = state.detail.photos.data;
      for (let i = 0; i < photos.length; i++) {
        if (photos[i].is_primary_photo) return photos[i];
      }
    }
    return null;
  },
  seoAddon(state) {
    let text = '';
    if (state.project && state.project.developer_name) text += `${state.project.developer_name} - `;
    text += 'BeliRumah';
    return text;
  },
  seoTitle(state, getters) {
    let text = '';
    text += state.detail ? state.detail.title : '';
    // if (text.length > 50) text = text.substring(0, 47) + '...';
    text += ' ' + getters.seoAddon;
    return text;
  },
  seoDesc(state, getters) {
    let text = getters.seoTitle;
    text += '. ';
    if (state.project) {
      text +=
        state.project.description != null ? state.project.description : state.project.project_desc;
    }
    if (text.length > 300) text = text.substring(0, 300) + '...';
    text += ' ' + getters.seoAddon;
    return text;
  },
};

export const mutations = {
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  set_image_index(state, index) {
    state.imageIndex = index;
  },
  SET_PROJECT(state, payload) {
    state.project = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_AGENT_UUID(state, payload) {
    state.agentUuid = payload;
  },
  SET_SELECTED_CATEGORY(state, payload) {
    state.selectedCategory = payload;
  },
};

export const actions = {
  async getDetail({ commit, state, rootState }, hashId) {
    try {
      let response = await this.$http.get('/api/project_unit_type/' + hashId);
      const data = response.data.data.data;
      console.log('RESULT GET UNIT TYPE DETAIL:', data);
      commit('SET_DETAIL', data);
      let project = data.project.data;
      project.listing_type = rootState.v2.masters.listingTypeEnum.PRIMARY;
      commit('SET_PROJECT', project);
      commit('SET_CATEGORIES', data.project.data.categories.data);
      commit('SET_SELECTED_CATEGORY', state.categories[0]);
    } catch (e) {
      console.log('ERROR GET UNIT TYPE DETAIL: ', e);
      commit('SET_DETAIL', null);
      commit('SET_PROJECT', null);
      commit('SET_CATEGORIES', []);
      commit('SET_SELECTED_CATEGORY', null);
      throw e;
    }
  },
  async selectCategory({ commit }, category) {
    commit('SET_SELECTED_CATEGORY', category);
  },
};
