export const state = () => ({
  selectedHelp: null,
  helpList: [
    {
      url: 'what-is-beliruma',
      title: 'FAQ.rentfixgeneral',
    },
    {
      url: 'about-project',
      title: 'FAQ.aboutProjectHeader',
    },
  ],
});

export const getters = {};

export const mutations = {
  SET_SELECTED_HELP(state, payload) {
    state.selectedHelp = payload;
  },
};

export const actions = {};
