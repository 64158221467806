export const state = () => ({
  projectUuid: '',
  projectData: null,
  name: '',
  phoneNumber: '',
  numberOfInstallment: 6,
  oneYearInstallment: 0,
  sixMonthsInstallment: 0,
  twoMonthsInstallment: 0,
  date: null,
  loading: false,
});

export const getters = {};

export const mutations = {
  SET_DATE(state, payload) {
    state.date = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PROJECT_UUID(state, payload) {
    state.projectUuid = payload;
  },
  SET_PROJECT_DATA(state, payload) {
    state.projectData = payload;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_PHONE_NUMBER(state, payload) {
    state.phoneNumber = payload;
  },
  SET_NUMBER_OF_INSTALLMENT(state, payload) {
    state.numberOfInstallment = payload;
  },
  SET_ONE_YEAR_INSTALLMENT(state, payload) {
    state.oneYearInstallment = payload;
  },
  SET_TWO_YEAR_INSTALLMENT(state, payload) {
    state.twoYearInstallment = payload;
  },
  SET_SIX_MONTHS_INSTALLMENT(state, payload) {
    state.sixMonthsInstallment = payload;
  },
};

export const actions = {
  async getData({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/fintech_installment/get_data', {
        params: {
          projectUuid: state.projectUuid,
        },
      });
      commit('SET_PROJECT_DATA', data.data.projectData);
      commit('SET_NAME', data.data.applicantData.name);
      commit('SET_PHONE_NUMBER', data.data.applicantData.phoneNumber);
      commit('SET_SIX_MONTHS_INSTALLMENT', data.data.cicilanData.sixMonthsInstallment);
      commit('SET_ONE_YEAR_INSTALLMENT', data.data.cicilanData.oneYearInstallment);
      commit('SET_TWO_YEAR_INSTALLMENT', data.data.cicilanData.twoYearInstallment);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async postRequest({ commit, state, rootState }) {
    try {
      commit('SET_LOADING', true);
      let postData = {
        name: state.name,
        phoneNumber: state.phoneNumber,
        numberOfInstallment: state.numberOfInstallment,
        projectUuid: state.projectUuid,
        date: this.$date.format(state.date, 'dd-MM-yyyy'),
      };
      if (!rootState.global.loggedIn) {
        postData.registerData = {
          name: rootState.signUp.name,
          email: rootState.signUp.email,
          password: rootState.signUp.password,
          phone: rootState.signUp.phone,
        };
      }
      // eslint-disable-next-line no-unused-vars
      const { data } = await this.$http.post('/api/v2/fintech_installment/initiate', postData);
      // if(data){
      //     await dispatch('updateToken', data.token,{root:true});
      //     await dispatch('fetch',{},{root:true});
      //     return true
      // }
      return true;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
