<template>
  <v-app>
    <sidebar-layout></sidebar-layout>
    <header-layout :class="homepage ? 'header--homepage' : ''" v-if="!useMobileParam" />
    <div class="header--height" v-if="!useMobileParam"></div>
    <!--    <div :style="{ height: !useMobileParam ? headerHeight + 'px' : 0 }"></div>-->
    <breadcrumb :breadcrumbs="breadcrumbs" />
    <slot />
    <footer-layout v-if="useFooter && $route.query.mobile !== 'true'" />
    <modal-login></modal-login>
    <modal-register></modal-register>
    <modal-forget-pass></modal-forget-pass>
    <modal-forget-pass-confirm></modal-forget-pass-confirm>
    <modal-consultation></modal-consultation>
    <modal-phone></modal-phone>
    <client-only>
      <validation-handler />
    </client-only>
  </v-app>
</template>

<script>
import Breadcrumb from '@/components/layouts/partials/breadcrumb';
const ValidationHandler = () => import('@/components/utils/validation-handler');
const HeaderLayout = () => import('@/components/layouts/header-layout');
const FooterLayout = () => import('@/components/layouts/footer-layout');
const SidebarLayout = () => import('@/components/layouts/sidebar');
const ModalLogin = () => import('@/components/auth/modals/modal-login');
const ModalRegister = () => import('@/components/auth/modals/modal-register');
const ModalForgetPass = () => import('@/components/auth/modals/modal-forget-pass');
const ModalForgetPassConfirm = () => import('@/components/auth/modals/modal-forget-pass-confirm');
const ModalConsultation = () => import('@/components/home/modals/modal-consultation');
const ModalPhone = () => import('@/components/auth/modals/modal-phone');
import { mapState } from 'vuex';
export default {
  head() {
    return this.head;
  },
  created() {},
  components: {
    Breadcrumb,
    HeaderLayout,
    FooterLayout,
    SidebarLayout,
    ValidationHandler,
    ModalLogin,
    ModalRegister,
    ModalForgetPass,
    ModalForgetPassConfirm,
    ModalConsultation,
    ModalPhone,
  },
  computed: {
    useMobileParam() {
      return this.$route.query.mobile === 'true';
    },
    headerHeight() {
      return this.$store.getters['global/getHeaderHeight']();
    },
    ...mapState({
      head: (state) => state.head.content,
    }),
  },
  props: {
    homepage: {
      type: Boolean,
      default: false,
    },
    useFooter: {
      type: Boolean,
      default: true,
    },
    breadcrumbs: { type: Array, default: () => null },
  },
};
</script>
