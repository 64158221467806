import formatDate from 'date-fns/format';
import addDate from 'date-fns/add';
import formatDateISO from 'date-fns/formatISO';
import isAfterDate from 'date-fns/isAfter';
import maxDate from 'date-fns/max';
import parseDate from 'date-fns/parse';
import parseDateISO from 'date-fns/parseISO';
import isDateFns from 'date-fns/isDate';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { enUS, id } from 'date-fns/locale';

class myDate {
  locales = { enUS, id };
  constructor(store) {
    this.store = store;
  }
  format(date, format, options) {
    // console.log('FORMATTING DATE: ' + date + ' USING FORMAT: ' + format);
    if (date) {
      const locale = this.store.state.global.locale === 'id' ? 'id' : 'enUS';
      let alteredOptions = options;
      if (alteredOptions) {
        alteredOptions.locale = this.locales[locale];
      } else {
        alteredOptions = {
          locale: this.locales[locale],
        };
      }
      if (!this.isDate(date)) date = this.parse(date);
      return formatDate(date, format, alteredOptions);
    } else {
      return null;
    }
  }
  add(date, duration) {
    if (!this.isDate(date)) date = this.parse(date);
    return addDate(date, duration);
  }
  formatISO(date, options) {
    return formatDateISO(date, options);
  }
  isAfter(date, dateToCompare) {
    if (!this.isDate(date)) date = this.parse(date);
    if (!this.isDate(dateToCompare)) dateToCompare = this.parse(dateToCompare);
    return isAfterDate(date, dateToCompare);
  }
  max(datesArray) {
    return maxDate(datesArray);
  }
  parse(dateString, formatString, referenceDate, options) {
    // console.log('PARSING DATE: ' + dateString + ' AND FORMAT STRING: ' + formatString);
    if (dateString) {
      if (this.isDate(dateString)) {
        return dateString;
      } else {
        if (formatString) {
          let date = parseDate(
            dateString,
            formatString,
            referenceDate ? referenceDate : new Date(),
            options,
          );
          // console.log('PARSE RESULT: ', date);
          return date;
        } else {
          let date = parseDateISO(dateString, options);
          // console.log('PARSE ISO RESULT: ', date);
          return date;
        }
      }
    } else {
      return new Date();
    }
  }
  isDate(value) {
    return isDateFns(value);
  }
  formatDistanceToNow(date, options) {
    if (date) {
      const locale = this.store.state.global.locale === 'id' ? 'id' : 'enUS';
      let alteredOptions = options;
      if (alteredOptions) {
        alteredOptions.locale = this.locales[locale];
      } else {
        alteredOptions = {
          locale: this.locales[locale],
        };
      }
      return formatDistanceToNow(date, alteredOptions);
    } else {
      return null;
    }
  }
}

export default {
  beforeCreate({ store }, inject) {
    // Inject httpClient eveywhere
    inject('date', new myDate(store));

    // You can use it everywhere in your app:
    // - In UVue context: `context.$http.get(...)`
    // - In your components: `this.$http.get(...)`
    // - In your store actions: `this.$http.get(...)`
  },
};
