export const state = () => ({
  propertyTypes: [],
  mortgageBanks: [],
  project: null,
  projectUnitType: null,
  isLoading: true,
  employmentStatuses: [
    {
      id: 1,
      name: 'Karyawan',
    },
    {
      id: 2,
      name: 'Profesional',
    },
    {
      id: 3,
      name: 'Wiraswasta',
    },
  ],
  fullname: null,
  dateOfBirth: null,
  identityNumber: null,
  address: null,
  email: null,
  contactNumber: null,
  employmentStatus: null,
  customEmploymentStatus: null,
  monthlyIncomeValue: null,
  monthlyIncomeValueMin: null,
  monthlyIncomeValueMax: null,
  identityImage: null,
  preferredBank: null,
  loanAmount: null,
  downPayment: null,
  interestRate: null,
  interestRateFixed: null,
  tenureInYear: null,
  tenureInYearFixed: null,
  termsAndConditions: false,
  propertyPrice: null,
  selectedPropertyType: null,
  propertyAddress: null,
  monthlyPayment: null,
  monthlyPaymentFixed: null,
  ltv: 0,
  defaultDP: 0.2,
});

export const getters = {
  allPrograms(state) {
    let programs = [
      {
        id: null,
        title: 'Tidak Ada Preferensi',
      },
    ];
    let i = 1;
    for (let bank of state.mortgageBanks) {
      if (bank.programs) {
        for (let program of bank.programs) {
          let tempProgram = JSON.parse(JSON.stringify(program));
          tempProgram.id = i;
          programs.push(tempProgram);
          i++;
        }
      }
    }
    return programs;
  },
  allBanks(state) {
    let banks = [
      {
        id: null,
        name: 'Tidak Ada Preferensi',
      },
    ];
    for (let bank of state.mortgageBanks) {
      banks.push(bank);
    }
    return banks;
  },
};

export const mutations = {
  SET_PROPERTY_TYPES(state, payload) {
    state.propertyTypes = payload;
  },
  SET_MORTGAGE_BANKS(state, payload) {
    state.mortgageBanks = payload;
  },
  SET_PROJECT(state, payload) {
    state.project = payload;
  },
  SET_PROJECT_UNIT_TYPE(state, payload) {
    state.projectUnitType = payload;
  },
  SET_LOADING_TRUE(state) {
    state.isLoading = true;
  },
  SET_LOADING_FALSE(state) {
    state.isLoading = false;
  },
  SET_LOAN_AMOUNT(state, payload) {
    state.loanAmount = payload;
  },
  SET_DOWN_PAYMENT(state, payload) {
    state.downPayment = payload;
  },
  SET_INTEREST_RATE(state, payload) {
    state.interestRate = payload;
  },
  SET_INTEREST_RATE_FIXED(state, payload) {
    state.interestRateFixed = payload;
  },
  SET_TENURE_IN_YEAR(state, payload) {
    state.tenureInYear = payload;
  },
  SET_TENURE_IN_YEAR_FIXED(state, payload) {
    state.tenureInYearFixed = payload;
  },
  SET_TERMS_CONDITIONS(state, payload) {
    state.termsAndConditions = payload;
  },
  SET_FULLNAME(state, payload) {
    state.fullname = payload;
  },
  SET_DATE_BIRTH(state, payload) {
    state.dateOfBirth = payload;
  },
  SET_IDENTITY_NUMBER(state, payload) {
    state.identityNumber = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_CONTACT_NUMBER(state, payload) {
    state.contactNumber = payload;
  },
  SET_EMPLOYMENT_STATUS(state, payload) {
    state.employmentStatus = payload;
  },
  SET_CUSTOM_EMPLOYMENT_STATUS(state, payload) {
    state.customEmploymentStatus = payload;
  },
  SET_MONTHLY_INCOME(state, payload) {
    state.monthlyIncomeValue = payload;
  },
  SET_MONTHLY_INCOME_MIN(state, payload) {
    state.monthlyIncomeValueMin = payload;
  },
  SET_MONTHLY_INCOME_MAX(state, payload) {
    state.monthlyIncomeValueMax = payload;
  },
  SET_IDENTITY_IMAGE(state, payload) {
    state.identityImage = payload;
  },
  SET_PREFERRED_BANK(state, payload) {
    state.preferredBank = payload;
  },
  SET_PROPERTY_PRICE(state, payload) {
    state.propertyPrice = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
  SET_PROPERTY_ADDRESS(state, payload) {
    state.propertyAddress = payload;
  },
  SET_MONTHLY_PAYMENT(state, payload) {
    state.monthlyPayment = payload;
  },
  SET_MONTHLY_PAYMENT_FIXED(state, payload) {
    state.monthlyPaymentFixed = payload;
  },
  SET_LTV(state, payload) {
    state.ltv = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.propertyTypes = [];
    state.mortgageBanks = [];
    state.project = null;
    state.loanAmount = null;
    state.downPayment = null;
    state.interestRate = null;
    state.interestRateFixed = null;
    state.tenureInYear = 0;
    state.tenureInYearFixed = 0;
    state.termsAndConditions = false;
    state.fullname = null;
    state.dateOfBirth = null;
    state.identityNumber = null;
    state.address = null;
    state.email = null;
    state.contactNumber = null;
    state.employmentStatus = null;
    state.customEmploymentStatus = null;
    state.monthlyIncomeValue = null;
    state.identityImage = null;
    state.preferredBank = null;
    state.propertyPrice = null;
    state.selectedPropertyType = null;
    state.propertyAddress = null;
    state.ltv = 0;
    state.defaultDP = 0.2;
    state.monthlyPayment = null;
    state.monthlyPaymentFixed = null;
  },
};

export const actions = {
  async getFormData({ commit, rootState }, { projectUuid, projectUnitTypeUuid, listingUuid }) {
    try {
      commit('SET_LOADING_TRUE');

      let { data } = await this.$http.get('/api/mortgage/get_form_data', {
        params: {
          project_uuid: projectUuid,
          project_unit_type_uuid: projectUnitTypeUuid,
          listing_uuid: listingUuid,
        },
      });
      commit('SET_PROPERTY_TYPES', data.data.property_types);
      commit('SET_MORTGAGE_BANKS', data.data.mortgage_banks);
      if (data.data.listing) {
        let project = data.data.listing;
        project.listing_type = rootState.v2.masters.listingTypeEnum.SECONDARY;
        commit('SET_PROJECT', project);
        commit('SET_PROPERTY_PRICE', project.price);
      } else if (data.data.project) {
        let project = data.data.project;
        project.listing_type = rootState.v2.masters.listingTypeEnum.PRIMARY;
        commit('SET_PROJECT', project);
        commit('SET_PROPERTY_PRICE', project.min_price_num);
      }
      if (data.data.project_unit_type) {
        commit('SET_PROJECT_UNIT_TYPE', data.data.project_unit_type);
        commit('SET_PROPERTY_PRICE', data.data.project_unit_type.min_price_num);
      }
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING_FALSE');
    }
  },
  async submitMortgageRequest({ commit, state }) {
    try {
      commit('SET_LOADING_TRUE');

      let { data } = await this.$http.post('/api/mortgage/submit', {
        project_uuid: state.project ? state.project.uuid : null,
        project_unit_type_uuid: state.projectUnitType ? state.projectUnitType.uuid : null,
        project_address: state.project ? state.project.address_line_2 : state.propertyAddress,
        sell_price:
          state.project && state.project.sell_price_raw
            ? state.project.sell_price_raw
            : state.propertyPrice,
        property_type_id: state.selectedPropertyType ? state.selectedPropertyType.id : null,
        full_name: state.fullname,
        // birth_date: state.dateOfBirth,
        contact_number: state.contactNumber,
        email: state.email,
        employment_status:
          state.employmentStatus && state.employmentStatus.id
            ? state.employmentStatus.name
            : state.customEmploymentStatus,
        // home_address: state.address,
        total_monthly_income_min: state.monthlyIncomeValue
          ? state.monthlyIncomeValue
          : state.monthlyIncomeValueMin,
        total_monthly_income_max: state.monthlyIncomeValueMax,
        // id_number: state.identityNumber,
        // id_number_image_uuid: state.identityImage ? state.identityImage.uuid : null,
        preferred_bank_ids:
          state.preferredBank && state.preferredBank.id ? [state.preferredBank.id] : [],
        // loan_amount: state.loanAmount,
        loan_amount: state.propertyPrice - state.downPayment,
        loan_tenure: state.tenureInYear,
      });

      commit('SET_LOADING_FALSE');
      return data;
    } catch (e) {
      console.log('SUBMIT MORTGAGE REQUEST ERROR: ', e);
      throw e;
    } finally {
      commit('SET_LOADING_FALSE');
    }
  },
  // eslint-disable-next-line no-unused-vars
  getLTV({ commit }, project) {
    // let propertyTypeCategoryId =
    //   project && project.property ? project.property.property_type_category_id : null;
    let ltv = 0.8;
    // if (propertyTypeCategoryId === 2) ltv = 0.75;
    // else if (propertyTypeCategoryId === 1) ltv = 0.7;
    commit('SET_LTV', ltv);
    return ltv;
  },
  calculateMortgage({ commit, state }) {
    let loanAmount = state.propertyPrice - state.downPayment;
    if (state.interestRateFixed && state.tenureInYearFixed) {
      let monthlyInterestRateFixed = state.interestRateFixed / 100 / 12;
      let tenureInMonthsFixed = state.tenureInYear * 12;
      let totalInterestRateFixed = Math.pow(1 + monthlyInterestRateFixed, tenureInMonthsFixed);
      let monthlyPaymentFixed =
        (loanAmount * totalInterestRateFixed * monthlyInterestRateFixed) /
        (totalInterestRateFixed - 1);
      commit('SET_MONTHLY_PAYMENT_FIXED', monthlyPaymentFixed);
      let paidAmount = monthlyPaymentFixed * 12 * state.tenureInYearFixed;
      console.log('PAID BY FIXED INTEREST RATE: ', paidAmount);
      // loanAmount -= paidAmount;
    } else {
      commit('SET_MONTHLY_PAYMENT_FIXED', null);
    }

    let monthlyInterestRate = state.interestRate / 100 / 12;
    let tenureInMonths =
      (state.tenureInYear - (state.tenureInYearFixed ? parseInt(state.tenureInYearFixed) : 0)) * 12;
    let totalInterestRate = Math.pow(1 + monthlyInterestRate, tenureInMonths);
    let monthlyPayment =
      (loanAmount * totalInterestRate * monthlyInterestRate) / (totalInterestRate - 1);
    commit('SET_MONTHLY_PAYMENT', monthlyPayment);
  },
};
