import Vue from 'vue';

export const state = () => ({
  loadingCreatePassword: false,
});

export const getters = {};

export const mutations = {
  SET_LOADING_CREATE_PASSWORD(state, payload) {
    state.loadingCreatePassword = payload;
  },
};

export const actions = {
  async createPassword({ commit }, password) {
    try {
      commit('SET_LOADING_CREATE_PASSWORD', true);
      const { data } = await this.$http.post('/api/create_password', {
        password,
      });
      return data.data;
    } finally {
      commit('SET_LOADING_CREATE_PASSWORD', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async signInWithFacebook({ commit, dispatch }) {
    try {
      const resultSign = await Vue.prototype.$fireAuth.signInWithPopup(
        Vue.prototype.$fireFacebookProvider,
      );
      return await dispatch('signInWithSocialMedia', resultSign);
    } catch (e) {
      console.log('SIGN IN FACEBOOK CATCH: ', e);
      // throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async signInWithGoogle({ commit, dispatch }) {
    try {
      const resultSign = await Vue.prototype.$fireAuth.signInWithPopup(
        Vue.prototype.$fireGoogleProvider,
      );
      return await dispatch('signInWithSocialMedia', resultSign);
    } catch (e) {
      console.log('SIGN IN GOOGLE CATCH: ', e);
      // throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async signInWithSocialMedia({ commit, dispatch }, resultSign) {
    try {
      // console.log('RESULT SIGN',resultSign);
      const userFirebase = await Vue.prototype.$fireAuth.currentUser;
      if (userFirebase !== null) {
        // console.log('Firebase Credential: ', resultSign.credential);
        const credential = {
          oauthIdToken: resultSign.credential.idToken
            ? resultSign.credential.idToken
            : resultSign.credential.accessToken,
          providerId: resultSign.credential.providerId,
          signInMethod: resultSign.credential.signInMethod,
        };
        const firebaseEmail = userFirebase.email;
        return await dispatch('signInWithFirebase', {
          credential: credential,
          email: firebaseEmail,
          displayName: userFirebase.providerData[0].displayName,
        });
      }
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async signInWithFirebase({ commit }, { credential, email, displayName }) {
    try {
      const { data } = await this.$http.post('/api/auth/r_sign_fbase', {
        credential,
        email,
        display_name: displayName,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async resendActivation({ commit }, email) {
    try {
      const { data } = await this.$http.post('/api/auth/resend_activation', {
        email,
      });
      return data.data;
    } catch (e) {
      throw e;
    }
  },
};
