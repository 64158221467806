export const state = () => ({
  loading: true,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  async visit({ commit }, { projectUuid, projectUnitTypeUuid, listingUuid }) {
    try {
      commit('SET_LOADING', true);
      let payload = {};
      if (projectUuid) {
        payload.project_uuid = projectUuid;
      } else if (projectUnitTypeUuid) {
        payload.project_unit_type_uuid = projectUnitTypeUuid;
      } else if (listingUuid) {
        payload.listing_uuid = listingUuid;
      }
      const result = await this.$http.post('/api/visit', payload);
      console.log('RESULT POST VISIT LISTING: ', result.data);
    } catch (e) {
      console.log('ERROR POST VISIT LISTING: ', e);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
