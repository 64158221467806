const requireContext = require.context('./booking', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  total_rent: 0,
  discount: 0,
  discount_price: 0,
  harga_sewa: 0,
  lamaSewa: '',
  endDateName: '',
  moveDateName: '',
  bookingOrder: {},
  categoryNavList: [
    { name: 'on_going', isActive: true, count: 0 },
    { name: 'completed', isActive: false, count: 0 },
    { name: 'cancel', isActive: false, count: 0 },
  ],
  sortByNavList: [
    { name: 'newest', isActive: true, data: 1 },
    { name: 'oldest', isActive: false, data: 2 },
  ],
  actionLoading: false,
  bookingOrders: [],
  meta: {},
  digitalSignTemplate: '',
  totalBookingOrders: {},
  successSubmit: false,
  signLoading: false,
  billingInfoLoading: false,
  startTime: 0,
});

export const getters = {
  activeCategory(state) {
    return state.categoryNavList.find((category) => {
      return category.isActive;
    }).name;
  },
  activeSortBy(state) {
    return state.sortByNavList.find(function (sortBy) {
      return sortBy.isActive === true;
    });
  },
};

export const mutations = {
  SET_START_TIME(state, payload) {
    state.startTime = payload;
  },
  SET_SIGN_LOADING(state, payload) {
    state.signLoading = payload;
  },

  SET_BILLING_INFO_LOADING(state, payload) {
    state.billingInfoLoading = payload;
  },
  set_total_rent(state, payload) {
    state.total_rent = payload;
  },
  set_harga_sewa(state, payload) {
    state.harga_sewa = payload;
  },
  set_discount(state, payload) {
    state.discount = payload;
  },
  set_discount_price(state, payload) {
    state.discount_price = payload;
  },
  set_lama_sewa(state, payload) {
    state.lamaSewa = payload;
  },
  set_end_date_name(state, payload) {
    state.endDateName = payload;
  },
  set_start_date_name(state, payload) {
    state.moveDateName = payload;
  },
  set_booking_order(state, payload) {
    state.bookingOrder = payload;
  },
  set_booking_orders(state, payload) {
    state.bookingOrders = payload;
  },
  set_meta(state, payload) {
    state.meta = payload;
  },
  set_active_category(state, selectedCategoryName) {
    state.categoryNavList.forEach(function (categoryNav_) {
      categoryNav_.isActive = categoryNav_.name === selectedCategoryName;
    });
  },
  set_active_sort_by(state, selectedSortByName) {
    state.sortByNavList.forEach(function (sortByNav_) {
      sortByNav_.isActive = sortByNav_.name === selectedSortByName;
    });
  },
  set_digital_sign_template(state, payload) {
    state.digitalSignTemplate = payload;
  },
  set_action_loading(state, payload) {
    state.actionLoading = payload;
  },
  update_category_count(state, payload) {
    const category = state.categoryNavList.find(function (category) {
      return category.name === payload.categoryName;
    });
    category.count = payload.count;
  },
  set_success_submit(state, payload) {
    state.successSubmit = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.total_rent = 0;
    state.discount = 0;
    state.discount_price = 0;
    state.harga_sewa = 0;
    state.lamaSewa = '';
    state.endDateName = '';
    state.moveDateName = '';
    state.bookingOrder = {};
    state.categoryNavList = [
      { name: 'on_going', isActive: true, count: 0 },
      { name: 'completed', isActive: false, count: 0 },
      { name: 'cancel', isActive: false, count: 0 },
    ];
    state.sortByNavList = [
      { name: 'newest', isActive: true, data: 1 },
      { name: 'oldest', isActive: false, data: 2 },
    ];
    state.actionLoading = false;
    state.bookingOrders = [];
    state.meta = {};
    state.digitalSignTemplate = '';
    state.totalBookingOrders = {};
    state.successSubmit = false;
    state.signLoading = false;
    state.billingInfoLoading = false;
    state.startTime = 0;
  },
};

export const actions = {
  async getTotalRent({ commit }, requestData) {
    let response = await this.$http.post('/api/v2/booking/get-total-rent', requestData);

    if (response.data.type === 'success') {
      commit('set_total_rent', response.data.data.total_rent);
      commit('set_harga_sewa', response.data.data.harga_sewa);
      commit('set_discount', response.data.data.discount);
      commit('set_discount_price', response.data.data.discount_price);
      commit('set_lama_sewa', response.data.data.lama_sewa);
      let formatDate;
      if (requestData.periode_id === 5) {
        formatDate = 'dd/MM/yyyy HH:mm:00';
      } else {
        formatDate = 'dd/MM/yyyy';
      }
      commit(
        'set_end_date_name',
        this.$date.format(this.$date.parse(response.data.data.end_date), formatDate),
      );
      commit(
        'set_start_date_name',
        this.$date.format(this.$date.parse(response.data.data.move_date), formatDate),
      );
    }
  },

  async getTotalRentRequest({ commit }, requestData) {
    let response = await this.$http.post('/api/v2/booking_request/get-total-rent', requestData);
    // console.log('SUCCESS getTotalRentRequest', response.data.data);

    if (response.data.type === 'success') {
      commit('set_total_rent', response.data.data.total_rent);
      commit('set_harga_sewa', response.data.data.harga_sewa);
      commit('set_discount', response.data.data.discount);
      commit('set_discount_price', response.data.data.discount_price);
      commit('set_lama_sewa', response.data.data.lama_sewa);
      let formatDate;
      if (requestData.periode_id === 5) {
        formatDate = 'dd/MM/yyyy HH:mm:00';
      } else {
        formatDate = 'dd/MM/yyyy';
      }
      commit(
        'set_end_date_name',
        this.$date.format(this.$date.parse(response.data.data.end_date), formatDate),
      );
      commit(
        'set_start_date_name',
        this.$date.format(this.$date.parse(response.data.data.move_date), formatDate),
      );
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async postBookingOrder({}, requestData) {
    let response = await this.$http.post('/api/v2/booking/post-booking-order', requestData);

    return response.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getBookingOrderBookRequest({}, bookingRequest) {
    let response = await this.$http.get('/api/v2/booking?booking_request_uuid=' + bookingRequest);

    return response.data;
  },

  async getBookingOrderFromUuid({ commit }, bookingUuid) {
    let response = await this.$http.get('/api/v2/booking?uuid=' + bookingUuid);

    commit('set_booking_order', response.data.data);
  },
  // eslint-disable-next-line no-empty-pattern
  async getBookingOrderFromProjectUuid({}, projectUuid) {
    let response = await this.$http.get('/api/v2/booking?project=' + projectUuid);

    return response.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async isBookingAllowed({}, projectUuid) {
    try {
      let response = await this.$http.get('/api/v2/booking/is_allowed?project_uuid=' + projectUuid);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },

  async getBookingOrder({ commit }, orderNumber) {
    let response = await this.$http.get('/api/v2/booking?order_number=' + orderNumber);

    if (response.data.type === 'success') {
      commit('set_booking_order', response.data.data);
      commit('set_total_rent', response.data.data.final_price);
      let formatDate;
      if (response.data.data.rent_period_id === 5) {
        formatDate = 'dd/MM/yyyy HH:mm:00';
      } else {
        formatDate = 'dd/MM/yyyy';
      }
      commit(
        'set_start_date_name',
        this.$date.format(this.$date.parse(response.data.data.start_date_time), formatDate),
      );
      commit(
        'set_end_date_name',
        this.$date.format(this.$date.parse(response.data.data.end_date_time), formatDate),
      );

      return response.data;
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async pay({}, requestData) {
    try {
      const result = await this.$http.post('/api/v2/booking/pay', requestData);
      return result.data;
    } catch (e) {
      throw e;
    }

    // return await this.$axios.post('/api/v2/booking/pay', requestData).then(result =>  {
    //     return result.data;
    // })
  },
  // eslint-disable-next-line no-empty-pattern
  async charge({}, requestData) {
    return await this.$http.post('/api/v2/booking/charge', requestData).then((result) => {
      return result.data;
    });
  },

  async getProjectBookingTransactions({ commit }, { page, category, sortBy }) {
    if (page === undefined) {
      page = 1;
    }

    if (category === undefined) {
      category = 'on_going';
    }

    if (sortBy === undefined) {
      sortBy = 'newest';
    }
    await commit('set_action_loading', true);
    let response = await this.$http.get('/api/v2/booking/get-project-booking-transactions', {
      params: {
        page: page,
        category: category,
        sortBy: sortBy,
      },
    });

    commit('set_booking_orders', response.data.data);
    commit('set_meta', response.data.meta);
    await commit('set_action_loading', false);
  },

  async fetchBookingOrders({ dispatch, getters }) {
    let category = getters.activeCategory;
    let sortBy = getters.activeSortBy;
    dispatch('getBookingOrders', {
      category: category,
      sortBy: sortBy.name,
    });
  },

  async getBookingOrders({ commit }, { page, category, sortBy }) {
    if (page === undefined) {
      page = 1;
    }

    if (category === undefined) {
      category = 'on_going';
    }

    if (sortBy === undefined) {
      sortBy = 'newest';
    }
    await commit('set_action_loading', true);
    let response = await this.$http.get('/api/v2/mybooking/get_all_bookings', {
      params: {
        page: page,
        category: category,
        sort_by: sortBy,
      },
    });
    console.log('RESPONSE BOOKING LIST', response.data);

    commit('set_booking_orders', response.data.data);
    commit('set_meta', response.data.meta);
    await commit('set_action_loading', false);
  },

  async getTotalBookingOrders({ commit }) {
    let response = await this.$http.get('/api/v2/mybooking/get_count', {
      params: {},
    });

    commit('update_category_count', {
      categoryName: 'completed',
      count: response.data.data.completed,
    });

    commit('update_category_count', {
      categoryName: 'on_going',
      count: response.data.data.on_going,
    });

    commit('update_category_count', {
      categoryName: 'cancel',
      count: response.data.data.canceled,
    });
  },

  async getTotalProjectBookingTransactions({ commit }) {
    let response = await this.$http.get('/api/v2/booking/get-total-project-booking-transactions');

    commit('update_category_count', {
      categoryName: 'completed',
      count: response.data.data.completed,
    });

    commit('update_category_count', {
      categoryName: 'on_going',
      count: response.data.data.on_going,
    });

    commit('update_category_count', {
      categoryName: 'cancel',
      count: response.data.data.canceled,
    });
  },

  async changeActiveCategory({ commit, dispatch, getters }, categoryName) {
    commit('set_active_category', categoryName);
    let sortBy = getters.activeSortBy;
    dispatch('getBookingOrders', {
      category: categoryName,
      sortBy: sortBy.name,
    });
  },

  async changeTransactionActiveCategory({ commit, dispatch, getters }, categoryName) {
    commit('set_active_category', categoryName);
    let sortBy = getters.activeSortBy;
    dispatch('getProjectBookingTransactions', {
      category: categoryName,
      sortBy: sortBy.name,
    });
  },

  async changeActiveSortBy({ commit, dispatch, getters }, sortName) {
    commit('set_active_sort_by', sortName);
    let category = getters.activeCategory;
    dispatch('getBookingOrders', { category: category, sortBy: sortName });
  },

  async changeTransactionActiveSortBy({ commit, dispatch, getters }, sortName) {
    commit('set_active_sort_by', sortName);
    let category = getters.activeCategory;
    dispatch('getProjectBookingTransactions', {
      category: category,
      sortBy: sortName,
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async getBlockedDate({}, requestData) {
    let response = await this.$http.get('/api/v2/booking/blocked-date/' + requestData.uuid, {
      params: {
        booking_uuid: requestData.booking_uuid,
      },
    });

    return response.data;
  },

  // eslint-disable-next-line no-empty-pattern
  async cancelBooking({}, requestData) {
    // let category = getters.activeCategory;
    // let sortBy = getters.activeSortBy;
    let response = await this.$http.post('/api/v2/booking/cancel-booking', requestData);
    if (response.data.type === 'success') {
      // dispatch('getBookingOrders', { category: category, sortBy: sortBy.name });
      return response.data;
    }
  },

  async serahTempat({ getters, dispatch }, requestData) {
    let category = getters.activeCategory;
    let sortBy = getters.activeSortBy;
    let response = await this.$http.post('/api/v2/booking/serah-tempat', requestData);
    if (response.data.type === 'success') {
      dispatch('getBookingOrders', { category: category, sortBy: sortBy.name });
      return response.data;
    }
  },

  async terimaTempat({ getters, dispatch }, requestData) {
    let category = getters.activeCategory;
    let sortBy = getters.activeSortBy;
    let response = await this.$http.post('/api/v2/booking/terima-tempat', requestData);
    if (response.data.type === 'success') {
      dispatch('getBookingOrders', { category: category, sortBy: sortBy.name });
      return response.data;
    }
  },

  async getDigitalSignTemplate({ commit }, bookinguuid) {
    await this.$http
      .get('/api/v2/booking/digital-sign-template/' + bookinguuid)
      .then((response) => {
        commit('set_digital_sign_template', response.data.data.template);
      });
  },

  async digitalSign({ commit }, bookinguuid) {
    try {
      commit('SET_SIGN_LOADING', true);
      let response = await this.$http.post('/api/v2/booking/sign-booking/' + bookinguuid);
      return response.data;
    } finally {
      commit('SET_SIGN_LOADING', false);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async downloadAgreement({}, bookingUuid) {
    await this.$http
      .get('/api/v1/download/' + bookingUuid + '/digital_agreement')
      .then((result) => {
        let filePdf = result.data.filePdf;
        window.open(filePdf, '_blank');
        // let blob = new Blob(filePdf,{type:'application/pdf'})
        // url = window.URL.createObjectURL(blob)
        // window.open(url);
      });
  },
};
