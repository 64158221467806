export const state = () => ({
  banners: null,
  bannerDetail: null,
  loading: true,
  copiedPromoCode: null,
});

export const mutations = {
  SET_COPIED_PROMO_CODE(state, payload) {
    state.copiedPromoCode = payload;
  },
  SET_BANNERS(state, payload) {
    state.banners = payload;
  },
  SET_BANNER_DETAIL(state, payload) {
    state.bannerDetail = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_BANNERS', []);
    commit('SET_BANNER_DETAIL', null);
    commit('SET_COPIED_PROMO_CODE', null);
  },
  async getBanner({ commit }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/banner/get_banner_list', {
        params: {
          type: 'USER',
        },
      });
      // console.log('GET BANNER DATA', data.data);
      commit('SET_BANNERS', data.data);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBannerDetail({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/banner/get_detail', {
        params: {
          uuid: uuid,
        },
      });
      commit('SET_BANNER_DETAIL', data.data);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
