const requireContext = require.context('./booking', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  duration: 1,
  rentPeriodId: 0,
  rentPeriodName: '',
  rentPrice: 0,
  totalRent: 0,
  bookingFee: 0,
  balance: 0,
  rentDurationName: '',
  endDate: '',
  availability: true,
  blockedDate: [],
  maxDate: new Date(),
  moveDate: new Date(),
  tommorowFromMaxDate: null,
  tommorowFromMaxDateValue: null,
  optionsHourly: {},
  startTime: null,
  endTime: null,
  projectOperational: {},
  isCheckAvailability: 0,
  userBilling: {},
  bookingOrder: {},
});

export const getters = {};

export const mutations = {
  SET_DURATION(state, payload) {
    state.duration = payload;
  },

  RESET_BOOKING_FORM(state) {
    state.duration = 1;
    state.rentPeriodId = 0;
    state.rentPrice = 0;
    state.totalRent = 0;
    state.bookingFee = 0;
    state.balance = 0;
    state.rentDurationName = '';
    state.rentPeriodName = '';
  },
  SET_FIRST_PRICE(state, payload) {
    state.rentPrice = payload.rentPrice;
    state.rentPeriodId = payload.rentPeriodId;
    state.rentPeriodName = payload.rentPeriodName;
  },

  SET_RENT_PERIOD_ID(state, payload) {
    state.rentPeriodId = payload.rentPeriodId;
    state.rentPrice = payload.rentPrice;
  },

  GET_TOTAL_RENT(state, payload) {
    state.totalRent = payload.totalRent;
    state.bookingFee = payload.bookingFee;
    state.rentDurationName = payload.lamaSewa;
    state.balance = payload.balance;
    state.rentPeriodName = payload.rentPeriod;
    state.endDate = payload.endDate;
  },
  SET_AVAILABILITY(state, payload) {
    state.availability = payload;
  },
  SET_MOVE_DATE(state, payload) {
    state.moveDate = this.$date.parse(payload);
  },
  SET_BLOCKED_DATE(state, payload) {
    state.blockedDate = payload.blockedDate;
    state.maxDate = payload.maxDate;
    state.tommorowFromMaxDate = payload.tommorowFromMaxDate;
    state.tommorowFromMaxDateValue = payload.tommorowFromMaxDateValue;
  },
  SET_OPTIONS_HOURLY(state, payload) {
    state.optionsHourly = payload;
    const selectedTime = payload.startTimes.find(function (startTime) {
      return startTime.selected === true;
    });
    const selectedEndTime = payload.endTimes.find(function (endTime) {
      return endTime.selected === true;
    });
    state.startTime = selectedTime.time;
    state.endTime = selectedEndTime.time;
  },
  SET_PROJECT_OPERATIONAL(state, payload) {
    state.projectOperational = payload;
  },
  SET_STARTTIME_INPUT(state, payload) {
    state.startTime = Number(payload);
  },
  SET_ENDTIME_INPUT(state, payload) {
    state.endTime = Number(payload);
  },
  SET_IS_CHECK_AVAILABILITY(state, payload) {
    state.isCheckAvailability = Number(payload);
  },
  SET_USER_BILLING(state, payload) {
    state.userBilling = payload;
  },
  SET_BOOKING_ORDER(state, payload) {
    state.bookingOrder = payload;
  },
};

export const actions = {
  async setRentPeriodId({ commit, rootState }, rentPeriodId) {
    let rentPrice = rootState.project.project.rentPrices.find(function (item) {
      return item.rent_period_id === rentPeriodId;
    });
    commit('SET_RENT_PERIOD_ID', {
      rentPeriodId: rentPeriodId,
      rentPrice: rentPrice.price,
    });
  },
  async getTotalRent({ commit }, requestData) {
    this.$http
      .post('/api/v1/getTotalRent', {
        duration: requestData.duration,
        startDate: requestData.startDate,
        rentPeriodID: requestData.rentPeriodID,
        project: requestData.project,
      })
      .then((response) => {
        commit('GET_TOTAL_RENT', response.data);
      });
  },

  async getTotalRentHourly({ state, commit }, requestData) {
    await this.$http
      .post('/api/v1/getTotalRentHourly', {
        startTime: state.startTime,
        endTime: requestData.endTime,
        startDate: state.moveDate,
        rentPeriodID: state.rentPeriodId,
        project: requestData.project,
      })
      .then((response) => {
        commit('GET_TOTAL_RENT', response.data);
      });
  },

  async setFirstStartTimeEndTime({ state, commit }) {
    let selectedStartTime = state.optionsHourly.startTimes.find(function (item) {
      return item.selected === true;
    });

    commit('SET_STARTTIME_INPUT', selectedStartTime.time);

    let selectedEndTime = state.optionsHourly.endTimes.find(function (item) {
      return item.selected === true;
    });

    commit('SET_ENDTIME_INPUT', selectedEndTime.time);
  },

  async getBlockedDate({ commit }, requestData) {
    let bookinguuid = requestData.bookinguuid !== undefined ? requestData.bookinguuid : '';

    let { data } = await this.$http.get(
      '/api/v1/' +
        requestData.projectUuid +
        '/blockedDate/' +
        requestData.rentPeriodId +
        '/' +
        bookinguuid,
    );
    let setBlockedDateData = {
      blockedDate: data.blockedDate,
      maxDate: data.maxDate !== null ? this.$date.parse(data.maxDate) : new Date(),
      tommorowFromMaxDate:
        data.maxDate !== null
          ? this.$date.add(this.$date.parse(data.maxDate), { days: 1 })
          : new Date(),
      tommorowFromMaxDateValue:
        data.maxDate !== null
          ? this.$date.add(this.$date.parse(data.maxDate), { days: 1 })
          : new Date(),
    };
    commit(
      'SET_MOVE_DATE',
      data.maxDate !== null
        ? this.$date.add(this.$date.parse(data.maxDate), { days: 1 })
        : new Date(),
    );
    commit('SET_BLOCKED_DATE', setBlockedDateData);
    return data;
  },

  async getOptionsHourly({ commit }, requestData) {
    await this.$http
      .post('/api/v1/' + requestData.projectUuid + '/getOptionsHourly', {
        moveDate: requestData.moveDate,
      })
      .then((response) => {
        commit('SET_OPTIONS_HOURLY', response.data);
      });
  },

  async checkAvailability({ state, commit }, requestData) {
    if (state.is_check_availability === 1) {
      if (state.rentPeriodId !== 5) {
        this.$http
          .post('/api/v1/checkAvailability', {
            duration: requestData.duration,
            project: requestData.project,
            rentPeriod: requestData.rentPeriod,
            move_date: requestData.moveDate,
            booking: requestData.bookinguuid !== undefined ? requestData.bookinguuid : null,
          })
          .then((response) => {
            commit('SET_AVAILABILITY', response.data.data.valid);
          });
      } else {
        commit('SET_AVAILABILITY', true);
      }
    } else {
      commit('SET_AVAILABILITY', true);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async checkIsHaveBooking({}, project) {
    return await this.$http
      .post('/api/v1/booking/checkIsHaveBooking', {
        project: project,
      })
      .then((response) => {
        return response;
      });
  },
  async getProjectOperational({ commit }, uuid) {
    await this.$http.get('/api/v1/' + uuid + '/projectOperational').then((response) => {
      commit('SET_PROJECT_OPERATIONAL', response.data.project_operational);
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async getBillingInformation({}) {
    return await this.$http.post('/api/v1/getBillingInformation').then((result) => {
      return result.data.data;
    });
  },
  async updateUser({ commit }, requestData) {
    return await this.$http.post('/api/v1/booking/updateUser', requestData).then((result) => {
      let user = result.data.data;
      user.jenis = requestData.jenis;
      commit('SET_USER_BILLING', user);

      return user;
    });
  },

  async bookNow({ commit }, requestData) {
    return await this.$http.post('/api/v1/booking/bookNow', requestData).then((result) => {
      commit('SET_BOOKING_ORDER', result.data.data);
      return result.data.data;
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async pay({}, requestData) {
    return await this.$http.post('/api/v1/booking/pay', requestData).then((result) => {
      return result.data;
    });
  },

  async getBookingOrder({ commit }, orderNumber) {
    return await this.$http.get('/api/v1/booking/edit/' + orderNumber).then((result) => {
      commit('SET_BOOKING_ORDER', result.data.data);
      return result.data.data;
    });
  },

  async getMybooking({ commit }, uuid) {
    return await this.$http.get('/api/v1/' + uuid + '/mybooking').then((result) => {
      commit('SET_BOOKING_ORDER', result.data.data);
      return result.data.data;
    });
  },
};
