export const state = () => ({
  project: {},
  imageIndex: null,
  floorPlanIndex: null,
  projects: [],
  meta: {},
  newProjects: [],
  partialRentProjects: [],
  premierProjects: [],
  residentialProjects: [],
  commercialProjects: [],
  stayProjects: [],
  businessProjects: [],
  inventoryProjects: [],
  embed360Projects: [],
  projectPartnerProjects: [],
  discountProjects: [],
  compareProjects: [],
  compareShow: false,
  agentUuid: null,
});

export const getters = {
  galleries(state) {
    if (Object.keys(state.project).length > 0) {
      let clonedPhotos = JSON.parse(
        JSON.stringify(
          'data' in state.project.photos ? state.project.photos.data : state.project.photos,
        ),
      );
      clonedPhotos.sort(function (a, b) {
        if (a.is_primary_photo === true) return -1;
        else if (b.is_primary_photo === true) return 1;
        else return 0;
      });

      let photos = clonedPhotos.map(function (photo) {
        return photo.url;
      });

      return photos ? photos : [];
    }
    return [];
  },
  floorPlanGalleries(state) {
    if (Object.keys(state.project).length > 0) {
      if (state.project.floor_plans) {
        let clonedPhotos = JSON.parse(JSON.stringify(state.project.floor_plans));
        let photos = clonedPhotos.map(function (photo) {
          return photo.url;
        });
        return photos ? photos : [];
      }
    }
    return [];
  },
  firstRentPrice(state) {
    if (Object.keys(state.project).length > 0) {
      return state.project.rent_prices[0];
    }
  },
  projects(state) {
    return state.projects;
  },
  compareShow(state) {
    return state.compareShow;
  },
  unitTypes(state) {
    if (state.project && state.project.unit_types) {
      let unitTypes = state.project.unit_types.data;
      for (let i = 0; i < unitTypes.length; i++) {
        unitTypes[i].property_type = state.project.property_type;
      }
      return unitTypes;
    }
    return [];
  },
  primaryPhoto(state) {
    if (state.project && state.project.photos && state.project.photos.data) {
      let photos = state.project.photos.data;
      for (let i = 0; i < photos.length; i++) {
        if (photos[i].is_primary_photo) return photos[i];
      }
    }
    return null;
  },
  seoAddon(state) {
    let text = '';
    if (state.project && state.project.developer_name) text += `${state.project.developer_name} - `;
    text += 'BeliRumah';
    return text;
  },
  seoTitle(state, getters) {
    let text = '';
    text += state.project ? state.project.title : '';
    // if (text.length > 50) text = text.substring(0, 47) + '...';
    text += ' ' + getters.seoAddon;
    return text;
  },
  seoDesc(state, getters) {
    let text = getters.seoTitle;
    text += '. ';
    if (state.project) {
      text +=
        state.project.description != null ? state.project.description : state.project.project_desc;
    }
    if (text.length > 300) text = text.substring(0, 300) + '...';
    text += ' ' + getters.seoAddon;
    return text;
  },
};

export const mutations = {
  set_project(state, payload) {
    state.project = payload;
  },
  set_image_index(state, index) {
    state.imageIndex = index;
  },
  set_floor_plan_index(state, index) {
    state.floorPlanIndex = index;
  },
  set_projects(state, { data, meta }) {
    state.projects = data;
    state.meta = meta;
  },
  set_new_projects(state, projects) {
    state.newProjects = projects;
  },
  set_premier_projects(state, projects) {
    state.premierProjects = projects;
  },
  set_partial_rent_projects(state, projects) {
    state.partialRentProjects = projects;
  },
  SET_PROJECT_PARTNER_PROJECTS(state, projects) {
    state.projectPartnerProjects = projects;
  },
  SET_DISCOUNT_PROJECTS(state, projects) {
    state.discountProjects = projects;
  },
  SET_RESIDENTIAL_PROJECTS(state, projects) {
    state.residentialProjects = projects;
  },
  SET_COMMERCIAL_PROJECTS(state, projects) {
    state.commercialProjects = projects;
  },
  SET_STAY_PROJECTS(state, projects) {
    state.stayProjects = projects;
  },
  SET_BUSINESS_PROJECTS(state, projects) {
    state.businessProjects = projects;
  },
  SET_INVENTORY_PROJECTS(state, projects) {
    state.inventoryProjects = projects;
  },
  SET_EMBED_360_PROJECTS(state, projects) {
    state.embed360Projects = projects;
  },
  set_compare_project(state, payload) {
    state.compareProjects.push(payload);
  },
  remove_compare_project(state, payload) {
    state.compareProjects.splice(state.compareProjects.indexOf(payload), 1);
  },
  reset_compare_project(state) {
    state.compareProjects = [];
  },
  set_compare_show(state, payload) {
    state.compareShow = payload;
  },
  set_agent_uuid(state, payload) {
    state.agentUuid = payload;
  },
};

export const actions = {
  async getProject({ commit, rootState }, hashId) {
    try {
      let response = await this.$http.get('/api/project/' + hashId);
      console.log('RESULT GET PROJECT:', response.data);
      let data = response.data.data.data;
      data.listing_type = rootState.v2.masters.listingTypeEnum.PRIMARY;
      commit('set_project', data);
    } catch (e) {
      console.log('ERROR GET PROJECT: ', e);
      commit('set_project', null);
      throw e;
    }
  },

  async getListing({ commit, rootState }, hashId) {
    try {
      let response = await this.$http.get('/api/listing/' + hashId);
      console.log('RESULT GET LISTING:', response.data);
      let data = response.data.data;
      data.listing_type = rootState.v2.masters.listingTypeEnum.SECONDARY;
      commit('set_project', data);
    } catch (e) {
      console.log('ERROR GET LISTING: ', e);
      commit('set_project', null);
      throw e;
    }
  },

  async getCategoryProjects({ commit }, { category, device }) {
    let query = {};

    query.paginate = 6;
    query.premier = true;

    let homePaginate = 3;
    if (device && device.isMobile) homePaginate = 2;
    let url = '/api/v2/search';
    switch (category) {
      case 'premier': {
        break;
      }
      case 'residential': {
        query.paginate = homePaginate;
        query.property_category = 7;
        delete query.premier;
        break;
      }
      case 'commercial': {
        query.paginate = homePaginate;
        query.property_category = 8;
        delete query.premier;
        break;
      }
      case 'stay': {
        query.paginate = homePaginate;
        query.property_category = 6;
        break;
      }
      case 'business': {
        query.paginate = homePaginate;
        query.property_category = 1;
        break;
      }
      case 'inventory': {
        query.paginate = homePaginate;
        query.property_category = 2;
        break;
      }
      case '360': {
        query.paginate = homePaginate;
        query.pano_required = true;
        break;
      }
      case 'projectpartner': {
        query.paginate = homePaginate;
        url = '/api/v2/search/project-partners';
        break;
      }
      case 'discount': {
        query.paginate = homePaginate;
        query.discount = true;
        break;
      }
    }

    await this.$http
      .get(url, {
        params: query,
      })
      .then((response) => {
        if (category === 'premier') {
          commit('set_premier_projects', response.data.data.data);
        } else if (category === 'residential') {
          commit('SET_RESIDENTIAL_PROJECTS', response.data.data.data);
        } else if (category === 'commercial') {
          commit('SET_COMMERCIAL_PROJECTS', response.data.data.data);
        } else if (category === 'stay') {
          commit('SET_STAY_PROJECTS', response.data.data.data);
        } else if (category === 'business') {
          commit('SET_BUSINESS_PROJECTS', response.data.data.data);
        } else if (category === 'inventory') {
          commit('SET_INVENTORY_PROJECTS', response.data.data.data);
        } else if (category === '360') {
          commit('SET_EMBED_360_PROJECTS', response.data.data.data);
        } else if (category === 'projectpartner') {
          commit('SET_PROJECT_PARTNER_PROJECTS', response.data.data.data);
        } else if (category === 'discount') {
          commit('SET_DISCOUNT_PROJECTS', response.data.data.data);
        } else if (category === 'new-projects') {
          commit('set_new_projects', response.data.data.data);
        } else if (category === 'partial-rent') {
          commit('set_partial_rent_projects', response.data.data.data);
        }
      });
  },
  // eslint-disable-next-line
  async addOrRemoveShortlist({}, {projectUuid, listingUuid}) {
    let data = {};
    if (projectUuid) data.project_uuid = projectUuid;
    if (listingUuid) data.listing_uuid = listingUuid;
    let response = await this.$http.post('/api/shortlist/toggle', data);

    return response.data.data;
  },
  getYoutubeId(_, youtubeUrl) {
    if (!youtubeUrl) return null;
    // eslint-disable-next-line no-useless-escape
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youtubeUrl.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return null;
    }
  },
  async getYoutubeEmbedUrl({ dispatch }, youtubeUrl) {
    if (youtubeUrl) {
      let youtubeId = await dispatch('getYoutubeId', youtubeUrl);
      if (youtubeId !== null) {
        return `https://www.youtube.com/embed/${youtubeId}`;
      }
      return null;
    }
    return null;
  },

  async getProjectFromBookingRequest({ commit }, uuid) {
    let response = await this.$http.get('/api/v2/projects/booking-request-project?uuid=' + uuid);

    commit('set_project', response.data.data);
  },
};
