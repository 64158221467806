/**
 * This plugin create an axios HTTP client to do request.
 * It handles tokens too to acess to private routes on API.
 */
import Cookies from 'js-cookie';
import Cookie from 'cookie';

export default {
  async beforeCreate(context) {
    let token;
    const { req, store, res, $http } = context;
    const cookieStr = process.browser ? document.cookie : req.headers.cookie;
    const cookies = require('cookie').parse(cookieStr || '') || {};
    token = cookies.BeliRumaApp;
    const locale = cookies.locale;
    store.commit('global/SET_TOKEN', token);
    store.commit('global/SET_LOCALE', locale);

    if (store.state.global.token) {
      $http.defaults.headers.common.Authorization = `Bearer ${store.state.global.token}`;
    }
    $http.defaults.headers.common['Content-Language'] = locale ? locale : 'id';
    if (process.browser) {
      // ...Browser
      if (token) {
        Cookies.set('BeliRumaApp', token, {
          expires: store.state.global.remember ? 365 : null,
        });
      } else {
        Cookies.remove('BeliRumaApp');
      }
      Cookies.set('locale', locale, { expires: 10 });
    } else {
      // ...Server
      let params = {
        domain: '/',
      };
      if (!token) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        params.expires = yesterday;
      }
      res.setHeader('Set-Cookie', Cookie.serialize('BeliRumaApp', token, params));
      res.setHeader(
        'Set-Cookie',
        Cookie.serialize('locale', locale, { domain: '/', expires: new Date() }),
      );
    }

    if (token) {
      try {
        const data = await $http.get('/api/user');
        if (data.data.data) {
          await store.commit('global/SET_USER', data.data.data);
        }
      } catch (e) {
        if (process.browser) {
          Cookies.remove('BeliRumaApp');
          Cookies.remove('user');
          store.commit('global/SET_USER', null);
        } else {
          let params = {
            domain: '/',
          };
          const today = new Date();
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          params.expires = yesterday;
          res.setHeader('Set-Cookie', Cookie.serialize('BeliRumaApp', token, params));
          res.setHeader('Set-Cookie', Cookie.serialize('user', null, params));
          store.commit('global/SET_USER', null);
        }
      }
    }
  },
};
