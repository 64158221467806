export const state = () => ({
  provinces: [],
  province: null,
  cities: [],
  city: null,
  districts: [],
  district: null,
  villages: [],
  village: null,
  bankAccountCheckPayload: null,
  checkBankAccountInbox: false,
  owner: null,
});

export const getters = {};

export const mutations = {
  SET_BANK_ACC_CHECK_PAYLOAD(state, payload) {
    state.bankAccountCheckPayload = payload;
  },
  SET_CHECK_BANK_ACC_INBOX(state, payload) {
    state.checkBankAccountInbox = payload;
  },
  set_provinces(state, provinces) {
    state.provinces = provinces;
  },
  set_province(state, province) {
    state.province = province;
  },
  set_cities(state, cities) {
    state.cities = cities;
  },
  set_city(state, city) {
    state.city = city;
  },
  set_districts(state, districts) {
    state.districts = districts;
  },
  set_district(state, district) {
    state.district = district;
  },
  set_villages(state, villages) {
    state.villages = villages;
  },
  set_village(state, village) {
    state.village = village;
  },
  SET_OWNER(state, payload) {
    state.owner = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async changeAvatar({ commit }, formData) {
    try {
      const response = await this.$http.post('/api/upload-profile', formData);
      if (response.data.error) {
        return response.data;
      } else {
        // commit('global/SET_USER', response.data.data, { root: true });
      }
      return response;
    } catch (e) {
      throw e;
    }
  },
  async changePassword({ commit }, formData) {
    const response = await this.$http.post('/api/change-password', formData);
    if (response.data.error) {
      return response.data;
    } else {
      commit('global/SET_USER', response.data.data, { root: true });
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateProfile({ commit }, formData) {
    const response = await this.$http.post('/api/profile/update', formData);
    commit('global/SET_USER', response.data.data, { root: true });
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async getOwner({ commit }, userHashId) {
    return await this.$http.get('/api/v2/projects/owner/' + userHashId).then((response) => {
      commit('SET_OWNER', response.data.data);
      return response.data.data;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async getAgent({ commit }, agentHashId) {
    return await this.$http.get('/api/agent/profile/' + agentHashId).then((response) => {
      commit('SET_OWNER', response.data.data);
      return response.data.data;
    });
  },
  async getBillingInformation() {
    return await this.$http.get('/api/v2/user/billing-information').then((response) => {
      return response.data;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async updateUserBillingInformation({}, requestData) {
    let response = await this.$http.put('/api/v2/user/update-user-billing', requestData);

    return response.data;
  },
  async checkUserCanAcceptBooking() {
    const { data } = await this.$http.get('/api/v2/profile/check/can_accept_booking');
    return data.data;
  },
  async checkUserCanAddProject() {
    const { data } = await this.$http.get('/api/v2/profile/check/can_add_project');
    return data.data;
  },
  async checkUserCanAddProjectUserId() {
    const { data } = await this.$http.get('/api/v2/profile/check/can_add_project_user_id');
    return data.data;
  },
};
