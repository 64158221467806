export const state = () => ({
  project: {},
  projects: [],
  metaProjects: {},
  compareProjects: [],
  shortlists: [],
  index: null,
  similarProjects: [],
  dayInNumber: [1, 2, 3, 4, 5, 6, 7],
  userProject: null,
});

export const getters = {
  compareShow(state) {
    return state.compareProjects.length > 0;
  },
  isShortlisted: (state) => (uuid) => {
    let shortlisted = state.shortlists.find(function (item) {
      return item.uuid === uuid;
    });

    return !!shortlisted;
  },
  galleries(state, getters, stateRoot, gettersRoot) {
    let baseUrl = gettersRoot['global/baseUrl'];
    if (state.project && state.project.images) {
      return state.project.images.map((item) => {
        return baseUrl + item.url;
      });
    }
  },
};

export const mutations = {
  SET_PROJECT(state, payload) {
    state.project = payload;
  },
  RESET_PROJECT(state) {
    state.project = {};
  },

  SET_INFINITE_PROJECTS(state, payload) {
    state.projects = state.projects.concat(payload);
  },

  SET_PROJECTS(state, payload) {
    state.projects = payload;
  },
  SET_METAPROJECTS(state, payload) {
    state.metaProjects = payload;
  },
  RESET_PROJECTS(state) {
    state.metaProjects = [];
    state.projects = [];
  },
  SET_COMPARE_PROJECT(state, payload) {
    state.compareProjects.push(payload);
  },
  REMOVE_COMPARE_PROJECT(state, payload) {
    state.compareProjects.splice(state.compareProjects.indexOf(payload), 1);
  },
  RESET_COMPARE_PROJECT(state) {
    state.compareProjects = [];
  },
  SET_SHORTLISTS(state, payload) {
    state.shortlists = payload;
  },
  SET_ADD_SHORTLISTS(state, payload) {
    state.shortlists.push(payload);
  },
  REMOVE_FROM_SHORTLISTS(state, payload) {
    state.shortlists.splice(state.shortlists.indexOf(payload), 1);
  },
  SET_CLICK_IMAGE(state, index) {
    state.index = index;
  },
  SET_SIMILAR_PROJECTS(state, payload) {
    state.similarProjects = payload;
  },
  RESET_SIMILAR_PROJECTS(state) {
    state.similarProjects = [];
  },
  SET_USER_PROJECT(state, payload) {
    state.userProject = payload;
  },
};

export const actions = {
  async getInfiniteProjects({ commit, rootState }, requestData) {
    await this.$http
      .get('/api/v1/getSearchProjects', {
        params: {
          page: requestData.page,
          propertyGroup: rootState.searchNavbar.propertyGroup,
          q:
            rootState.searchNavbar.cityId === '' || rootState.searchNavbar.cityId === null
              ? rootState.searchNavbar.q
              : null,
          city: rootState.searchNavbar.cityId,
          building_size_min: rootState.searchNavbar.building_size_min,
          building_size_max: rootState.searchNavbar.building_size_max,
          propertyType: rootState.searchNavbar.propertyType,
          rentType: rootState.searchNavbar.rentType,
          rangePrice: rootState.searchNavbar.rangePrice,
          sortBy: rootState.searchNavbar.sortBy,
          sortOrder: rootState.searchNavbar.sortOrder,
        },
      })
      .then((result) => {
        commit('SET_INFINITE_PROJECTS', result.data.data);
        commit('SET_METAPROJECTS', result.data.meta);
        return result.data.meta;
      });
  },

  async getProject({ commit }, uuid) {
    return await this.$http.get('/api/v1/' + uuid + '/getProject').then((result) => {
      commit('SET_PROJECT', result.data.data);
      return result.data.data;
    });
  },
  async addOrRemoveShortlist({ state, commit }, project) {
    let shortlist = state.shortlists.find(function (item) {
      return item.uuid === project.uuid;
    });
    let isAdd = false;

    if (shortlist === undefined) {
      isAdd = true;
      commit('SET_ADD_SHORTLISTS', project);
    } else {
      commit('REMOVE_FROM_SHORTLISTS', project);
    }
    // eslint-disable-next-line
    let { data: response } = await this.$http
      .post('/api/project/toggle_shortlist', { project_uuid: project.uuid })
      // eslint-disable-next-line
      .catch(e => {
        if (isAdd) {
          commit('REMOVE_FROM_SHORTLISTS', project);
        } else {
          commit('SET_ADD_SHORTLISTS', project);
        }
      });
  },

  async getDetailProject({ commit, rootState }, hashId) {
    let isLoggedIn = rootState.global.loggedIn;
    let url;
    if (isLoggedIn) {
      url = '/api/v1/project/detail/' + hashId;
    } else {
      url = '/api/v1/project/' + hashId + '/detail';
    }

    return await this.$http.get(url).then((result) => {
      commit('SET_PROJECT', result.data.data);
      return result.data.data;
    });
  },

  async getSimilar({ commit }, uuid) {
    await this.$http.get('/api/v1/' + uuid + '/similarProjects').then((result) => {
      commit('SET_SIMILAR_PROJECTS', result.data.data);
    });
  },

  async getUserProject({ commit }, userHashId) {
    await this.$http.post('/api/v1/user/' + userHashId).then((result) => {
      commit('SET_USER_PROJECT', result.data.data);
    });
  },

  async getUserProjects({ commit, rootState }, requestData) {
    await this.$http
      .get('/api/v1/user/projects/' + requestData.uuid, {
        params: {
          page: requestData.page,
          propertyGroup: rootState.searchNavbar.propertyGroup,
          q:
            rootState.searchNavbar.cityId === '' || rootState.searchNavbar.cityId === null
              ? rootState.searchNavbar.q
              : null,
          city: rootState.searchNavbar.cityId,
          building_size_min: rootState.searchNavbar.building_size_min,
          building_size_max: rootState.searchNavbar.building_size_max,
          propertyType: rootState.searchNavbar.propertyType,
          rentType: rootState.searchNavbar.rentType,
          rangePrice: rootState.searchNavbar.rangePrice,
          sortBy: rootState.searchNavbar.sortBy,
          sortOrder: rootState.searchNavbar.sortOrder,
        },
      })
      .then((result) => {
        commit('SET_PROJECTS', result.data.data);
        commit('SET_METAPROJECTS', result.data.meta);
      });
  },

  async getPartnerProjects({ commit, rootState }, requestData) {
    await this.$http
      .get('/api/v1/partner/' + requestData.partnerName + '/projects', {
        params: {
          page: requestData.page,
          q:
            rootState.searchNavbar.cityId === '' || rootState.searchNavbar.cityId === null
              ? rootState.searchNavbar.q
              : null,
          city: rootState.searchNavbar.cityId,
          building_size_min: rootState.searchNavbar.building_size_min,
          building_size_max: rootState.searchNavbar.building_size_max,
          propertyType: rootState.searchNavbar.propertyType,
          rentType: rootState.searchNavbar.rentType,
          rangePrice: rootState.searchNavbar.rangePrice,
          sortBy: rootState.searchNavbar.sortBy,
          sortOrder: rootState.searchNavbar.sortOrder,
        },
      })
      .then((result) => {
        commit('SET_PROJECTS', result.data.data);
        commit('SET_METAPROJECTS', result.data.meta);
      });
  },
};
