export const state = () => ({
  date: null,
  time: '08:00',
  loading: false,
  to: null,
  from: null,
  tourProgUuid: null,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_TOUR_PROG_UUID(state, payload) {
    state.tourProgUuid = payload;
  },
  SET_TO(state, payload) {
    state.to = payload;
  },
  SET_FROM(state, payload) {
    state.from = payload;
  },
  SET_DATE(state, payload) {
    state.date = payload;
  },
  SET_TIME(state, payload) {
    state.time = payload;
  },
};

export const actions = {
  async submit({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      let postData = {
        date: this.$date.format(state.date, 'dd-MM-yyyy'),
        time: state.time,
        tourScheduleProgressUuid: state.tourProgUuid,
      };
      const { data } = await this.$http.post('/api/v2/tour_schedule_prog/reschedule', postData);
      const responseData = data.data;
      return responseData;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
