export const state = () => ({
  privateMessage: {},
  users: [],
  project: {},
  messages: [],
  slidePanel: false,
});

export const mutations = {
  SET_PRIVATE_MESSAGE(state, payload) {
    state.privateMessage = payload;
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_PROJECT(state, payload) {
    state.project = payload;
  },
  SET_MESSAGES(state, payload) {
    state.messages = payload;
  },
  SET_SEND_MESSAGE(state, payload) {
    state.messages.push(payload);
  },
  SET_SLIDE_PANEL(state, payload) {
    state.slidePanel = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async contactHost({}, requestData) {
    return await this.$http
      .post('/api/v1/' + requestData.uuid + '/contact_host', {
        message: requestData.message,
        applicant_id: requestData.applicantId,
      })
      .then((result) => {
        return result.data;
      });
  },

  async getPrivateMessage({ commit }, formData) {
    let url;
    if (formData.privateMessageUuid === '') {
      url = '/api/v1/getPrivateMessage';
    } else {
      url = '/api/v1/getPrivateMessage/' + formData.privateMessageUuid;
    }
    return await this.$http.get(url).then((response) => {
      commit('SET_PRIVATE_MESSAGE', response.data.data);
      return response.data.data;
    });
  },

  async getPrivateMessageOnProject({ commit }, uuid) {
    await this.$http.get('/api/v1/getPrivateMessageOnProject/' + uuid).then((response) => {
      commit('SET_PRIVATE_MESSAGE', response.data.data);
    });
  },

  async getChatUsers({ commit }) {
    await this.$http.post('/api/v1/myinboxusers').then((response) => {
      commit('SET_USERS', response.data);
    });
  },

  async getProjectOnChat({ commit }, uuid) {
    await this.$http.get('/api/v1/myProjectOnChat/' + uuid).then((response) => {
      commit('SET_PROJECT', response.data);
    });
  },

  async getMessages({ commit }, requestData) {
    await this.$http.post('/api/v1/getChat', requestData).then((result) => {
      commit('SET_MESSAGES', result.data);
    });
  },

  async sendMessage({ commit }, formData) {
    commit('SET_SEND_MESSAGE', formData);
    await this.$http.post('/api/v1/createMessage', {
      privatemessage: formData.privatemessage,
      message: formData.message,
    });
  },
};
