// const requireContext = require.context('./checkout', false, /.*\.js$/);
// const modulesTemp = requireContext
//   .keys()
//   .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
//   .reduce((modules, [name, module]) => {
//     if (module.namespaced === undefined) {
//       module.namespaced = true;
//     }
//     return { ...modules, [name]: module };
//   }, {});
//
// export const modules = modulesTemp;
import xss from 'xss';

export const state = () => ({
  // projectUuid: null,
  // bookingInfo: null,
  // loading: true,
  ownerUniqueNumber: null,
  sellTransactionUuid: null,
  fkpTemplate: null,
  fkpSignLoading: false,
});

export const getters = {
  // formData(state, getters) {
  //   const billingInfoFormData = getters['billingInfo/formData'];
  //   return Object.assign(
  //     {
  //       project_uuid: state.projectUuid,
  //     },
  //     billingInfoFormData,
  //   );
  // },
};

export const mutations = {
  SET_OWNER_UNIQUE_NUMBER(state, payload) {
    state.ownerUniqueNumber = payload;
  },
  SET_FKP_TEMPLATE(state, payload) {
    state.fkpTemplate = payload;
  },
  SET_SELL_TRANSACTION_UUID(state, payload) {
    state.sellTransactionUuid = payload;
  },
  SET_FKP_SIGN_LOADING(state, payload) {
    state.fkpSignLoading = payload;
  },
  // SET_BOOKING_INFO(state, payload) {
  //   state.bookingInfo = payload;
  // },
  // SET_LOADING(state, payload) {
  //   state.loading = payload;
  // },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_OWNER_UNIQUE_NUMBER', null);
  },

  async getFkpTemplate({ commit, state }, ownerUniqueNumber) {
    // await this.$http.get('/api/v2/booking/digital-sign-template/' + bookinguuid).then(response => {
    //   commit('set_digital_sign_template', response.data.data.template);
    // });
    try {
      commit('SET_FKP_SIGN_LOADING', true);
      let { data } = await this.$http.get(
        `/api/v2/sell_transaction/get_fkp_template/${state.sellTransactionUuid}`,
        {
          params: {
            owner_unique_number: ownerUniqueNumber,
          },
        },
      );
      commit('SET_FKP_TEMPLATE', data.data.template);
      // commit('SET_BOOKING_INFO', data.data.booking_info);
    } finally {
      commit('SET_FKP_SIGN_LOADING', false);
    }
  },

  async signFkp({ commit, state }) {
    try {
      commit('SET_FKP_SIGN_LOADING', true);
      let { data } = await this.$http.post('/api/v2/sell_transaction/sign_fkp', {
        uuid: state.sellTransactionUuid,
        owner_unique_number: xss(state.ownerUniqueNumber),
      });
      return data.data;
    } finally {
      commit('SET_FKP_SIGN_LOADING', false);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadFkp({}, sellTransactionUuid) {
    await this.$http
      .get('/api/v2/sell_transaction/download_fkp', {
        params: {
          uuid: sellTransactionUuid,
        },
      })
      .then((result) => {
        let filePdf = result.data.filePdf;
        window.open(filePdf, '_blank');
        // let blob = new Blob(filePdf,{type:'application/pdf'})
        // url = window.URL.createObjectURL(blob)
        // window.open(url);
      });
  },

  // eslint-disable-next-line no-empty-pattern
  async ownerAccept({}, { uuid, finalPrice }) {
    let { data } = await this.$http.post('/api/v2/sell_transaction/owner_accept', {
      uuid,
      final_price: finalPrice,
    });
    return data.data;
  },

  // eslint-disable-next-line no-empty-pattern
  async cancel({}, uuid) {
    let { data } = await this.$http.post('/api/v2/sell_transaction/cancel', {
      uuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async confirmBookingFee({}, uuid) {
    let { data } = await this.$http.post('/api/v2/sell_transaction/confirm_booking_fee', {
      uuid,
    });
    return data.data;
  },
};
