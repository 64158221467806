export const state = () => ({
  fullname: '',
  email: '',
  phone: '',
  age: '',
  gender: '',
  genderPreference: '',
  minAgePreference: '',
  maxAgePreference: '',
  startDate: '',
  propertyType: '',
  lengthOfStay: '',
  rentPeriod: '',
  minBudget: '',
  maxBudget: '',
  propertyTypes: [],
  propertyTypeCategories: [],
  propertyTypeCategoryGroups: [],
  rentPeriods: [],
  location: '',
  loading: false,
  allowedRentPeriods: [],
});

export const getters = {
  selectedPropertyTypeCategoryGroup(state) {
    const poType = state.propertyTypeCategoryGroups.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType;
    }
    return null;
  },
  filteredPropertyTypeCategories(state, getters) {
    if (getters.selectedPropertyTypeCategoryGroup != null) {
      return state.propertyTypeCategories.filter((category) => {
        return category.propertyTypeCategoryGroupId == getters.selectedPropertyTypeCategoryGroup.id;
      });
    }
    return [];
  },
  filteredRentPeriods(state) {
    if (state.allowedRentPeriods.length === 0) return [];
    const rentPeriods = JSON.parse(JSON.stringify(state.rentPeriods));
    return rentPeriods.filter((rentPeriod) => {
      return state.allowedRentPeriods.includes(rentPeriod.id);
    });
  },
  // eslint-disable-next-line no-unused-vars
  formattedPropertyTypes(state, getters) {
    // let allData = {};
    // state.propertyTypes.forEach(function(element) {
    //   if (!allData[element.propertyTypeCategoryId]) {
    //     allData[element.propertyTypeCategoryId] = [];
    //     allData[element.propertyTypeCategoryId].push(element);
    //   } else {
    //     allData[element.propertyTypeCategoryId].push(element);
    //   }
    // });
    return state.propertyTypes;
  },
  async selectedPropertyType(state) {
    const poType = await state.propertyTypes.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType;
    }
    return null;
  },
  selectedRentPeriod(state) {
    let selectedRentType = state.rentPeriods.find(function (rentType) {
      return rentType.selected;
    });
    if (selectedRentType) {
      return selectedRentType;
    }
    return null;
  },
};

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.propertyTypes = [];
    state.propertyTypeCategories = [];
    state.rentPeriods = [];
    state.location = '';
    state.loading = false;
    state.allowedRentPeriods = [];
    state.fullname = '';
    state.email = '';
    state.phone = '';
    state.age = '';
    state.gender = '';
    state.genderPreference = '';
    state.minAgePreference = '';
    state.maxAgePreference = '';
    state.startDate = '';
    state.propertyType = '';
    state.lengthOfStay = '';
    state.rentPeriod = '';
    state.minBudget = '';
    state.maxBudget = '';
  },
  SET_FULLNAME(state, payload) {
    state.fullname = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
  SET_AGE(state, payload) {
    state.age = payload;
  },
  SET_GENDER(state, payload) {
    state.gender = payload;
  },
  SET_GENDER_PREFERENCE(state, payload) {
    state.genderPreference = payload;
  },
  SET_MIN_AGE_PREFERENCE(state, payload) {
    state.minAgePreference = payload;
  },
  SET_MAX_AGE_PREFERENCE(state, payload) {
    state.maxAgePreference = payload;
  },
  SET_START_DATE(state, payload) {
    state.startDate = payload;
  },
  SET_PROPERTY_TYPE(state, payload) {
    state.propertyType = payload;
  },
  SET_LENGTH_OF_STAY(state, payload) {
    state.lengthOfStay = payload;
  },
  SET_RENT_PERIOD(state, payload) {
    state.rentPeriod = payload;
  },
  SET_MIN_BUDGET(state, payload) {
    state.minBudget = payload;
  },
  SET_MAX_BUDGET(state, payload) {
    state.maxBudget = payload;
  },
  SET_PROPERTY_TYPE_CATEGORY_GROUPS(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      if (index_ === 0) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
    state.propertyTypeCategoryGroups = payload;
  },
  SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUPS(state, id) {
    state.propertyTypeCategoryGroups.forEach(function (property, index_, arr_) {
      if (property.id == id) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_ALLOWED_RENT_PERIODS(state, payload) {
    state.allowedRentPeriods = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PROPERTY_TYPES(state, payload) {
    payload.forEach(function (propertyType, index, arr) {
      arr[index].selected = false;
    });
    state.propertyTypes = payload;
  },
  SET_PROPERTY_TYPE_CATEGORIES(state, payload) {
    state.propertyTypeCategories = payload;
  },
  SET_RENT_PERIODS(state, payload) {
    payload.forEach(function (propertyType, index, arr) {
      arr[index].selected = false;
    });
    state.rentPeriods = payload;
  },
  SET_LOCATION(state, payload) {
    state.location = payload;
  },
  SET_SELECTED_RENT_PERIOD(state, rentPeriodId) {
    state.rentPeriods.forEach(function (rentPeriod, index_, arr_) {
      if (rentPeriod.id == rentPeriodId) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_ACTIVE_PROPERTY_TYPE(state, propertyTypeId) {
    state.propertyTypes.forEach(function (propertyType, index, arr) {
      if (propertyType.id === propertyTypeId) {
        arr[index].selected = true;
      } else {
        arr[index].selected = false;
      }
    });
  },
};

export const actions = {
  async changePropertyType({ commit, dispatch }, propertyTypeId) {
    commit('SET_LOADING', true);
    commit('SET_ACTIVE_PROPERTY_TYPE', propertyTypeId);
    commit('SET_SELECTED_RENT_PERIOD', null);
    await dispatch('getPropTypeRules', propertyTypeId);
  },

  async getData({ commit }) {
    let data = null;
    try {
      commit('SET_LOADING', true);
      data = await this.$http.get('/api/v2/corenting/get_data');
      commit('SET_PROPERTY_TYPES', data.data.data.propertyTypes);
      commit('SET_PROPERTY_TYPE_CATEGORY_GROUPS', data.data.data.propertyTypeCategoryGroups);
      commit('SET_PROPERTY_TYPE_CATEGORIES', data.data.data.propertyTypeCategories);
      commit('SET_RENT_PERIODS', data.data.data.rentPeriods);
    } finally {
      commit('SET_LOADING', false);
    }
    return data.data.data;
  },

  async getPropTypeRules({ commit }, propertyTypeId) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/corenting/get_property_type_rules', {
        params: { propertyTypeId },
      });
      commit('SET_ALLOWED_RENT_PERIODS', data.data.allowedRentPeriods);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async postRequest({ state, commit, dispatch }) {
    try {
      commit('SET_LOADING', true);
      let postData = {
        fullname: state.fullname,
        email: state.email,
        phone: state.phone,
        age: state.age,
        gender: state.gender ? state.gender.id : '',
        gender_preference: state.genderPreference ? state.genderPreference.id : '',
        min_age_preference: state.minAgePreference,
        max_age_preference: state.maxAgePreference,
        start_date: state.startDate,
        property_type_id: state.propertyType ? state.propertyType.id : '',
        rent_period: state.lengthOfStay,
        rent_period_id: state.rentPeriod ? state.rentPeriod.id : '',
        min_budget: state.minBudget,
        max_budget: state.maxBudget,
        location: state.location,
      };
      const { data } = await this.$http.post('/api/v2/corenting/post_request', postData);
      if (data.data) {
        await dispatch('updateToken', data.data.token, { root: true });
        await dispatch('fetch', {}, { root: true });
        commit('RESTORE_INITIAL_STATE');
        return true;
      }
      return true;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
