export const state = () => ({
  newsList: [],
  mostReadList: [],
  newsDetail: null,
  nextNewsList: [],
  metaNews: null,
  newsCategories: [],
  selectedCategory: {
    id: null,
    id_name: 'latest',
    name: 'Latest',
  },
  isLoading: true,
});

export const mutations = {
  SET_NEWS_LIST(state, payload) {
    state.newsList = payload;
  },
  SET_MOST_READ_LIST(state, payload) {
    state.mostReadList = payload;
  },
  SET_NEWS_DETAIL(state, payload) {
    state.newsDetail = payload;
  },
  SET_NEXT_NEWS_LIST(state, payload) {
    state.nextNewsList = payload;
  },
  SET_META_NEWS(state, payload) {
    state.metaNews = payload;
  },
  SET_NEWS_CATEGORIES(state, payload) {
    state.newsCategories = payload;
  },
  SET_SELECTED_CATEGORY(state, payload) {
    state.selectedCategory = payload;
  },
  SET_LOADING_TRUE(state) {
    state.isLoading = true;
  },
  SET_LOADING_FALSE(state) {
    state.isLoading = false;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.newsList = [];
    state.mostReadList = [];
    state.newsDetail = null;
    state.nextNewsList = [];
    state.metaNews = null;
    state.newsCategories = [];
    state.selectedCategory = {
      id: null,
      id_name: 'latest',
      name: 'Latest',
    };
  },
};

export const actions = {
  async getList({ commit, state }, page = 1) {
    commit('SET_LOADING_TRUE');

    try {
      let { data } = await this.$http.get('/api/v2/news/list', {
        params: {
          most_read_month: false,
          category_id: state.selectedCategory.id,
          per_page: 8,
          page: page,
        },
      });
      commit('SET_NEWS_LIST', data.data);
      commit('SET_META_NEWS', data.meta);
    } catch (e) {
      throw e;
    }

    commit('SET_LOADING_FALSE');
  },
  async getMostRead({ commit, state }) {
    commit('SET_LOADING_TRUE');

    try {
      let { data } = await this.$http.get('/api/v2/news/list', {
        params: {
          most_read_month: true,
          category_id: state.selectedCategory.id,
        },
      });
      commit('SET_MOST_READ_LIST', data.data);
    } catch (e) {
      throw e;
    }

    commit('SET_LOADING_FALSE');
  },
  async getDetail({ commit }, hashId) {
    commit('SET_LOADING_TRUE');

    try {
      let { data } = await this.$http.get('/api/v2/news/detail/' + hashId);
      commit('SET_NEWS_DETAIL', data.data.news);
      commit('SET_NEXT_NEWS_LIST', data.data.next_news);
    } catch (e) {
      throw e;
    }

    commit('SET_LOADING_FALSE');
  },
  async getNewsCategories({ commit }) {
    commit('SET_LOADING_TRUE');

    try {
      let { data } = await this.$http.get('/api/v2/master/news-categories');
      let list = data.data;
      if (list && Array.isArray(list))
        list.unshift({
          id: null,
          id_name: 'latest',
          name: 'Latest',
        });
      commit('SET_NEWS_CATEGORIES', list);
    } catch (e) {
      throw e;
    }

    commit('SET_LOADING_FALSE');
  },
};
