export const state = () => ({
  date: null,
  time: '08:00',
  userInput: '',
  loading: false,
  to: null,
  from: null,
  bookingOrderUuid: null,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_BOOKING_ORDER_UUID(state, payload) {
    state.bookingOrderUuid = payload;
  },
  SET_TO(state, payload) {
    state.to = payload;
  },
  SET_FROM(state, payload) {
    state.from = payload;
  },
  SET_DATE(state, payload) {
    state.date = payload;
  },
  SET_TIME(state, payload) {
    state.time = payload;
  },
  SET_USER_INPUT(state, payload) {
    state.userInput = payload;
  },
};

export const actions = {
  async submit({ commit, state, rootState }) {
    try {
      commit('SET_LOADING', true);
      let postData = {
        date: this.$date.format(state.date, 'dd-MM-yyyy'),
        time: state.time,
        // projectUuid: projectUuid,
        userInput: state.userInput,
        bookingOrderUuid: state.bookingOrderUuid,
      };
      if (!rootState.global.loggedIn) {
        postData.registerData = {
          name: rootState.signUp.name,
          email: rootState.signUp.email,
          password: rootState.signUp.password,
          phone: rootState.signUp.phone,
        };
      }
      console.log('POST DATA', postData);
      const { data } = await this.$http.post('/api/v2/tour_schedule/initiate', postData);
      const responseData = data.data;
      // if (responseData) { // untuk login
      //   await dispatch('updateToken', responseData.token, { root: true });
      //   await dispatch('fetch', {}, { root: true });
      // }

      return responseData;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
