export const state = () => ({
  mybookings: [],
  category: 'all',
  filter: 'on_going',
  metaMyBookings: {},
  bookingOrder: null,
  digitalSignTemplate: '',
  successSubmit: false,
});

export const getters = {};

export const mutations = {
  SET_MY_BOOKINGS(state, payload) {
    state.mybookings = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },

  SET_FILTER(state, payload) {
    state.filter = payload;
  },
  SET_META_MYBOOKINGS(state, payload) {
    state.metaMyBookings = payload;
  },
  RESET_CATEGORY_FILTER(state) {
    state.category = 'all';
    state.filter = 'on_going';
  },
  SET_BOOKING_ORDER(state, payload) {
    state.bookingOrder = payload;
  },
  SET_DIGITAL_SIGN_TEMPLATE(state, payload) {
    state.digitalSignTemplate = payload;
  },
  SET_SUCCESS_SUBMIT(state, payload) {
    state.successSubmit = payload;
  },
};

export const actions = {
  async getMyBookings({ state, commit }, page) {
    await this.$http
      .get('/api/v1/my-booking', {
        params: {
          page: page,
          category: state.category,
          filter: state.filter,
        },
      })
      .then((result) => {
        commit('SET_MY_BOOKINGS', result.data.data);
        commit('SET_META_MYBOOKINGS', result.data.meta);
      });
  },
  async getCaraPembayaran({ commit }, bookingOrderUuid) {
    await this.$http.get('/api/v1/my-booking/caraPembayaran/' + bookingOrderUuid).then((result) => {
      commit('SET_BOOKING_ORDER', result.data.data);
    });
  },
  // eslint-disable-next-line no-unused-vars
  async rescheduleBooking({ state, commit }, requestData) {
    return await this.$http
      .put('/api/v1/mybooking/reschedule', {
        uuid: requestData.bookinguuid,
        move_date: requestData.rescheduleDate,
      })
      .then((response) => {
        return response.data;
      });
  },

  async getDigitalSignTemplate({ commit }, bookinguuid) {
    await this.$http.post('/api/v1/' + bookinguuid + '/getDigitalSignTemplate').then((response) => {
      commit('SET_DIGITAL_SIGN_TEMPLATE', response.data.text);
    });
  },

  async digitalSign(context, bookinguuid) {
    return await this.$http
      .post('/api/v1/sign', {
        bookinguuid: bookinguuid,
      })
      .then((response) => {
        return response.data;
      });
  },

  // eslint-disable-next-line no-unused-vars
  async cancelBooking({ commit }, formData) {
    return await this.$http
      .post('/api/v1/mybooking/cancel', {
        uuid: formData.uuid,
        message: formData.message,
      })
      .then((response) => {
        return response.data;
      });
  },

  async downloadAgreement({ rootState }, bookingUuid) {
    await this.$http
      .get('/api/v1/download/' + bookingUuid + '/digital_agreement')
      .then((result) => {
        let filePdf = rootState.global.baseUrl + result.data.filePdf;
        window.open(filePdf, '_blank');
        // let blob = new Blob(filePdf,{type:'application/pdf'})
        // url = window.URL.createObjectURL(blob)
        // window.open(url);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async terimaTempat({ commit }, uuid) {
    return await this.$http
      .post('/api/v1/terimaTempat', {
        uuid: uuid,
      })
      .then((response) => {
        return response.data;
      });
  },

  // eslint-disable-next-line no-unused-vars
  async tidakTerimaTempat({ commit }, uuid) {
    return await this.$http
      .post('/api/v1/tolakTempat', {
        uuid: uuid,
      })
      .then((response) => {
        return response.data;
      });
  },
};
