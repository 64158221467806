export const state = () => ({
  testimonies: null,
  isLoading: true,
});

export const mutations = {
  SET_TESTIMONIES(state, payload) {
    state.testimonies = payload;
  },
  SET_LOADING_TRUE(state) {
    state.isLoading = true;
  },
  SET_LOADING_FALSE(state) {
    state.isLoading = false;
  },
};

export const actions = {
  async getList({ commit }) {
    commit('SET_LOADING_TRUE');
    let { data } = await this.$http.get('/api/v2/testimony/get_list');
    commit('SET_TESTIMONIES', data.data);

    commit('SET_LOADING_FALSE');
  },
};
