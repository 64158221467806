const requireContext = require.context('./projectForm', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  projectUuid: '',
  isLoading: false,
  fetchingEditProjectData: false,
  isPublished: false,
  defaultIsPublished: false,
  form: '',
  page: '',
  completeFormModal: {
    show: false,
    action: null,
  },
  formCurrentStep: 1,
});

export const getters = {
  isCompleteFormModalShow(state) {
    return state.completeFormModal.show;
  },
  completeFormModalAction(state) {
    return state.completeFormModal.action;
  },
  allFormData(state, getters) {
    const sect1 = getters['sect1/formData'];
    const sect2 = getters['sect2/formData'];
    const projectUuid = state.projectUuid;
    if (projectUuid !== '') {
      return Object.assign({ projectUuid: projectUuid }, sect1, sect2);
    }
    return Object.assign({}, sect1, sect2);
  },
};

export const mutations = {
  RESTORE_DEFAULT_ADD_STATE(state) {
    const defaultVal = JSON.parse(JSON.stringify(state.defaultState));
    state.isPublished = defaultVal.isPublished;
  },

  RESTORE_INITIAL_STATE(state) {
    state.isPublished = false;
    state.defaultIsPublished = false;
    state.projectUuid = '';
    state.formCurrentStep = 1;
    state.isLoading = false;
    state.completeFormModal = {
      show: false,
      action: null,
    };
  },
  SET_FORM_CURRENT_STEP(state, form) {
    state.formCurrentStep = form;
  },
  CLOSE_COMPLETE_FORM_MODAL(state) {
    state.completeFormModal.show = false;
    state.completeFormModal.action = null;
  },
  OPEN_COMPLETE_FORM_MODAL(state, action) {
    state.completeFormModal.show = true;
    state.completeFormModal.action = action;
  },
  SET_FETCHING_EDIT_PROJECT_DATA(state, payload) {
    state.fetchingEditProjectData = payload;
  },
  SET_FORM(state, form) {
    state.form = form;
  },
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_IS_PUBLISHED(state, form) {
    state.isPublished = form;
  },
  SET_DEFAULT_IS_PUBLISHED(state) {
    const currentState = JSON.parse(
      JSON.stringify({
        isPublished: state.isPublished,
      }),
    );
    state.defaultIsPublished = currentState.isPublished;
  },
  RESTORE_DEFAULT_IS_PUBLISHED(state) {
    const defaultState = JSON.parse(
      JSON.stringify({
        isPublished: state.defaultIsPublished,
      }),
    );
    state.isPublished = defaultState.isPublished;
  },
  SET_PROJECT_UUID(state, projectUuid) {
    state.projectUuid = projectUuid;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const actions = {
  async saveDraft({ state, commit, getters }) {
    try {
      let formData = getters.allFormData;
      if (state.isPublished === true) {
        formData.is_publish = 1;
      }
      let url = '/api/v2/project_form/add/save_draft';

      // const {
      //   data: { data: responseData },
      // } = await this.$axios.post(url, formData);
      await this.$http.post(url, formData);
      // commit('sect2/availability/SET_ALLOW_OPERATIONAL_HOUR', responseData.allowProjectOperational);
      // commit('SET_PROJECT_UUID', responseData.projectUuid);
      // if (responseData.propertyUuid) {
      //     commit('sect1.location/SET_PROPERTY_UUID', responseData.propertyUuid);
      // }
      // commit('SET_DEFAULT_IS_PUBLISHED');
      return true;
    } catch (e) {
      commit('RESTORE_DEFAULT_IS_PUBLISHED');
      return false;
    }
  },

  async postData({ state, commit, getters }) {
    try {
      let formData = getters.allFormData;
      if (state.isPublished === true) {
        formData.is_publish = 1;
      }
      let url;
      if (state.form === 'add') {
        url = '/api/v2/project_form/add';
      } else {
        url = '/api/v2/project_form/edit';
      }
      // console.log('FORM DATAAAAAAAAA',JSON.stringify(formData));

      const {
        data: { data: responseData },
      } = await this.$http.post(url, formData);
      //
      // console.log('DATARESPONSE POST', responseData);
      commit('sect2/availability/SET_ALLOW_OPERATIONAL_HOUR', responseData.allowProjectOperational);
      commit('SET_PROJECT_UUID', responseData.projectUuid);
      if (responseData.propertyUuid) {
        commit('sect1/location/SET_PROPERTY_UUID', responseData.propertyUuid);
      }
      commit('SET_DEFAULT_IS_PUBLISHED');
      return true;
    } catch (e) {
      commit('RESTORE_DEFAULT_IS_PUBLISHED');
      return false;
    }
  },

  async applyBaseData({ dispatch, state }, baseData) {
    return new Promise(async (resolve, reject) => {
      try {
        const form = state.form;
        const applySect1 = dispatch('sect1/applyBaseData', {
          form,
          baseData,
        });
        const applySect2 = dispatch('sect2/applyBaseData', {
          form,
          baseData,
        });

        await Promise.all[(applySect1, applySect2)];
        resolve();
      } catch (e) {
        reject();
      }
    });
  },

  async applyEditData({ state, commit, dispatch }, editData) {
    const form = state.form;
    await dispatch('sect1/applyEditData', {
      form,
      editData,
    });
    await dispatch('sect2/applyEditData', {
      form,
      editData,
    });
    commit('SET_IS_PUBLISHED', editData.is_published);
    commit('SET_PROJECT_UUID', editData.project_uuid);
  },

  async fetchBaseData({ commit, dispatch, getters, state }) {
    const form = state.form;
    let responseData;
    let countryId;
    if (form === 'add') {
      countryId = getters['location/selectedCountry'];
      const {
        data: { data: resultData },
      } = await this.$http.get('/api/v2/project_form/add/get_data', {
        params: {
          countryId: countryId,
        },
      });
      responseData = resultData;
      await dispatch('applyBaseData', responseData);
      // commit('location/SET_PROPERTY_TYPE_CATEGORY_GROUP_LIST',responseData.propertyTypeCategoryGroup);
      // commit('location/SET_PROPERTY_TYPE_CATEGORY_LIST',responseData.propertyTypeCategory);
    } else {
      commit('SET_FETCHING_EDIT_PROJECT_DATA', true);
      const {
        data: { data: resultData },
      } = await this.$http.get('/api/v2/project_form/edit/get_data/' + state.projectUuid);
      responseData = resultData;
      await dispatch('applyBaseData', responseData.baseData);
      await dispatch('applyEditData', responseData.editData);
    }

    commit('SET_DEFAULT_IS_PUBLISHED');
  },
  resetAllFormState({ commit, dispatch }) {
    commit('RESTORE_INITIAL_STATE');
    dispatch('sect1/restoreInitialState');
    dispatch('sect2/restoreInitialState');
    commit('sect1/regionalProjectForm/RESTORE_INITIAL_STATE');
  },
};
