export const state = () => ({
  cities: [],
  selectedCity: {},
});

export const getters = {};

export const mutations = {
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_SELECTED_CITY(state, payload) {
    state.selectedCity = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.cities = [];
    state.selectedCity = {};
  },
};

export const actions = {
  async getDirectoryData({ commit }, propertyTypeName) {
    try {
      const response = await this.$http.get('/api/directory/get_data', {
        params: {
          property_type: propertyTypeName,
        },
      });
      commit('SET_CITIES', response.data && response.data.data ? response.data.data.cities : []);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getDirectoryProject({ commit }, { propertyType }) {
    try {
      const response = await this.$http.get('/api/search', {
        params: {
          property_type_id: propertyType ? propertyType.id : null,
          paginate: 4,
        },
      });

      return response.data.data.data;
    } catch (e) {
      throw e;
    }
  },
  setSelectedCityById({ commit, state }, payload) {
    let selectedCity = state.cities.find(function (item) {
      return item.id === payload;
    });
    commit('SET_SELECTED_CITY', selectedCity);

    return null;
  },
};
