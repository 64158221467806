export const state = () => ({
  promoCode: null,
  promoDetail: null,
  chosenPromo: null,
  isLoading: false,
  warningMessage: null,
});

export const mutations = {
  SET_PROMO_DETAIL(state, payload) {
    state.promoDetail = payload;
  },
  SET_CHOSEN_PROMO(state, payload) {
    state.chosenPromo = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_WARNING_MESSAGE(state, payload) {
    state.warningMessage = payload;
  },
  SET_PROMO_CODE(state, payload) {
    state.promoCode = payload;
  },
};

export const actions = {
  async restoreInitialState({ commit }) {
    commit('SET_PROMO_DETAIL', null);
    commit('SET_PROMO_CODE', null);
    commit('SET_WARNING_MESSAGE', null);
  },

  async checkPromo({ commit }, { promoCode, projectUuid }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/promo/check_promo_code', {
        params: {
          promo_code: promoCode,
          project_uuid: projectUuid,
        },
      });
      console.log('data', data);
      if (data) commit('SET_PROMO_DETAIL', data.data.promo);
      commit('SET_WARNING_MESSAGE', data.data.warning);
      return data;
      // eslint-disable-next-line no-empty
    } catch (e) {
    } finally {
      commit('SET_LOADING', true);
    }
  },
};
