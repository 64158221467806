export const state = () => ({
  transactionData: null,
  loading: false,
});

export const getters = {
  // eslint-disable-next-line no-unused-vars
  miniTimeline(state, getters, rootState) {
    if (!state.transactionData) return [];
    let result = [];
    let onGoing = false;
    for (let i = 0; i < state.transactionData.billing_timeline.length; i++) {
      if (onGoing) {
        break;
      }
      result.push(state.transactionData.billing_timeline[i]);
      if (state.transactionData.billing_timeline[i].timestamp === null) {
        onGoing = true;
      }
    }
    if (result.length > 3) {
      return result.slice(result.length - 3, result.length);
    }
    return result;
  },
};

export const mutations = {
  SET_TRANSACTION_DATA(state, payload) {
    state.transactionData = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  async getData({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get(`/api/transaction/${uuid}`);
      console.log('asdasdada', data);
      commit('SET_TRANSACTION_DATA', data.data);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
