export const state = () => ({
  propertyTypes: null,
});

export const mutations = {
  SET_DATA(state, payload) {
    state.propertyTypes = payload;
  },
};

export const actions = {
  async getData({ commit }) {
    try {
      const { data } = await this.$http.get('/api/v2/home/property-types');
      commit('SET_DATA', data.data);
    } catch (e) {
      throw e;
    }
  },
};
