export const state = () => ({
  // document: {
  //   project_type: 'R',
  //   created_at: '2020-12-02T15:04:47+07:00',
  //   address: 'Ini saya ubah ya, Jxiciiwowk, Kabupaten Pandeglang',
  //   owner_name: 'Firmansyah Ramadhan',
  //   applicant_name: 'firman yopmail xxx nol tiga',
  //   property_type: 'Mall',
  //   duration: '2 Bulan',
  //   invoice_number: 'INV/RENTFIX-328406420028',
  //   paid_at: null,
  //   period: '04 Desember 2020 sampai 04 Februari 2021',
  //   rent_price: 5000000,
  //   promo_discount: 500000,
  //   pay_amount: 4500000,
  // },
  // document: {
  //   project_type: 'S',
  //   created_at: '2020-11-26T13:49:21+07:00',
  //   address: 'Perumahan Palem Semi, Jalan palem aren no 2, Kabupaten Tangerang',
  //   owner_name: 'Firmansyah Ramadhan',
  //   applicant_name: 'firmanyopmail xxx nol empat',
  //   property_type: 'Toko',
  //   invoice_number: 'INV/SELL-326806430068',
  //   paid_at: '2020-11-26T13:49:23+07:00',
  //   sell_price: 5995000000,
  //   booking_fee: 500000,
  //   pay_amount: 500000,
  // },
  document: null,
  isLoading: true,
});

export const mutations = {
  SET_DOCUMENT(state, payload) {
    if (payload.rent_price) {
      payload.project_type = 'R';
    } else if (payload.sell_price) {
      payload.project_type = 'S';
    }
    state.document = payload;
  },
  SET_LOADING_TRUE(state) {
    state.isLoading = true;
  },
  SET_LOADING_FALSE(state) {
    state.isLoading = false;
  },
};

export const actions = {
  async getInvoice({ commit }, { orderNumber, token }) {
    commit('SET_LOADING_TRUE');
    let options = {
      params: {
        order_number: orderNumber,
      },
    };
    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    let { data } = await this.$http.get('/api/v2/transaction_doc/invoice', options);
    commit('SET_DOCUMENT', data.data);

    commit('SET_LOADING_FALSE');
  },
};
