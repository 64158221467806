import { CancelToken } from 'axios';

export const state = () => ({
  categoryNavList: [
    { name: 'active', isActive: true, count: 0 },
    { name: 'draft', isActive: false, count: 0 },
    { name: 'notActive', isActive: false, count: 0 },
    { name: 'occupied', isActive: false, count: 0 },
  ],
  sortByNavList: [
    { name: 'newest', isActive: true, data: 1 },
    { name: 'oldest', isActive: false, data: 2 },
    { name: 'highestPrice', isActive: false, data: 3 },
    { name: 'lowestPrice', isActive: false, data: 4 },
  ],
  projectItemList: [],
  metaPagination: {},
  isFetchingProject: true,
  actionLoading: false,
  searchProjectOptions: [],
  currentKeyword: '',
  getMyProjectToken: null,
  prevPage: '',
  activeSidebar: true,
});

export const getters = {
  allowedCategory(state) {
    return state.categoryNavList;
  },
  keywordText(state) {
    if (state.currentKeyword) {
      return state.currentKeyword.text;
    } else {
      return '';
    }
  },
  keywordId(state) {
    if (state.currentKeyword) {
      return state.currentKeyword.id;
    } else {
      return '';
    }
  },
  getCategory: (state) => (index) => {
    return state.categoryNavList[index];
  },
  getSortBy: (state) => (index) => {
    return state.sortByNavList[index];
  },
  activeCategory(state) {
    return state.categoryNavList.find((category) => {
      return category.isActive;
    }).name;
  },
  activeCategoryObject(state) {
    return state.categoryNavList.find((category) => {
      return category.isActive;
    });
  },
  activeSortBy(state) {
    return state.sortByNavList.find(function (sortBy) {
      return sortBy.isActive === true;
    });
  },
  getProject: (state) => (index) => {
    return state.projectItemList[index];
  },
};

export const mutations = {
  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },
  SET_GET_MY_PROJECT_TOKEN(state, payload) {
    state.getMyProjectToken = payload;
  },
  SET_META_PAGINATION(state, meta) {
    state.metaPagination = meta;
  },
  SET_CURRENT_KEYWORD(state, keyword) {
    state.currentKeyword = keyword;
  },
  SET_ACTIVE_CATEGORY(state, selectedCategoryName) {
    state.categoryNavList.forEach(function (categoryNav_) {
      categoryNav_.isActive = categoryNav_.name === selectedCategoryName;
    });
  },
  SET_ACTIVE_SORT_BY(state, selectedSortByName) {
    state.sortByNavList.forEach(function (sortByNav_) {
      sortByNav_.isActive = sortByNav_.name === selectedSortByName;
    });
  },
  SET_LOADING_TRUE(state) {
    state.isFetchingProject = true;
  },
  SET_LOADING_FALSE(state) {
    state.isFetchingProject = false;
  },
  SET_PROJECT_ITEM_LIST(state, projectItemList) {
    state.projectItemList = projectItemList;
  },
  SET_ACTIVE_SIDEBAR(state, payload) {
    state.activeSidebar = payload;
  },
  UPDATE_CATEGORY_COUNT(state, payload) {
    const category = state.categoryNavList.find(function (category) {
      return category.name === payload.categoryName;
    });
    category.count = payload.count;
    // state.projectItemList = projectItemList;
  },
  RESET_PROJECT_ITEMS(state) {
    state.projectItemList = [];
  },
  UPDATE_SEARCH_OPTIONS(state, options) {
    state.searchProjectOptions = options.filter((option) => {
      return option.id !== '' && option.id != null;
    });
  },
  RESTORE_INITIAL_STATE(state) {
    state.categoryNavList = [
      { name: 'active', isActive: true, count: 0 },
      { name: 'draft', isActive: false, count: 0 },
      { name: 'notActive', isActive: false, count: 0 },
      { name: 'occupied', isActive: false, count: 0 },
    ];
    state.sortByNavList = [
      { name: 'newest', isActive: true, data: 1 },
      { name: 'oldest', isActive: false, data: 2 },
      { name: 'highestPrice', isActive: false, data: 3 },
      { name: 'lowestPrice', isActive: false, data: 4 },
    ];
    state.projectItemList = [];
    state.metaPagination = {};
    state.isFetchingProject = true;
    state.actionLoading = false;
    state.searchProjectOptions = [];
    state.currentKeyword = '';
    state.getMyProjectToken = null;
  },
};

export const actions = {
  changeActiveCategory({ commit, dispatch }, selectedCategoryName) {
    commit('SET_CURRENT_KEYWORD', '');
    commit('SET_ACTIVE_CATEGORY', selectedCategoryName);
    dispatch('getProjects');
    dispatch('fetchSearchProjectOptions');
    dispatch('getCategoryCountData');
  },
  changeActiveSortBy({ commit, dispatch }, selectedSortByName) {
    commit('SET_ACTIVE_SORT_BY', selectedSortByName);
    dispatch('getProjects', this.prevPage);
    dispatch('getCategoryCountData');
  },
  getCategoryCountData({ commit }) {
    this.$http.get('/api/v1/myproject/getTotalActiveProject').then((response) => {
      commit('UPDATE_CATEGORY_COUNT', {
        categoryName: 'active',
        count: response.data,
      });
    });
    this.$http.get('/api/v1/myproject/getTotalNotActiveProject').then((response) => {
      commit('UPDATE_CATEGORY_COUNT', {
        categoryName: 'notActive',
        count: response.data,
      });
    });
    this.$http.get('/api/v1/myproject/getTotalDraftProject').then((response) => {
      commit('UPDATE_CATEGORY_COUNT', {
        categoryName: 'draft',
        count: response.data,
      });
    });
  },
  fetchSearchProjectOptions({ getters, commit }) {
    return new Promise(async (resolve) => {
      const response = await this.$http.get('/api/v1/getMyProject/getMyProjectSearchData', {
        params: {
          category: getters.activeCategory,
        },
      });
      commit('UPDATE_SEARCH_OPTIONS', response.data.data);
      resolve();
    });
  },
  async getProjects({ commit, dispatch }, page) {
    commit('RESET_PROJECT_ITEMS');
    commit('SET_LOADING_TRUE');
    if (page === undefined) {
      page = 1;
    }
    this.prevPage = page;
    const response = await dispatch('fetchProjectRequest', page);
    commit('SET_PROJECT_ITEM_LIST', response.data.data);
    commit('SET_META_PAGINATION', response.data.meta);
    commit('SET_LOADING_FALSE');
  },
  fetchProjectRequest({ state, commit, getters }, page) {
    if (state.getMyProjectToken) {
      state.getMyProjectToken.cancel('Request canceled.');
    }
    commit('SET_GET_MY_PROJECT_TOKEN', CancelToken.source());
    return this.$http.get(
      '/api/v2/myproject/get_projects/' + getters.activeCategory + '/' + getters.activeSortBy.data,
      {
        cancelToken: state.getMyProjectToken.token,
        params: {
          keyword: getters.keywordId,
          page: page,
        },
      },
    );
  },
  // eslint-disable-next-line no-empty-pattern
  async notOccupiedProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/set_project_status/not_occupied', {
      projectUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async occupiedProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/set_project_status/occupied', {
      projectUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async deactivateProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/set_project_status/not_active', {
      projectUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async duplicateProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/duplicate', {
      project_uuid: projectUuid,
    });
    return data.data.uuid;
  },
  // eslint-disable-next-line no-empty-pattern
  async activateProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/set_project_status/active', {
      projectUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async checkPublishProject({}, projectUuid) {
    const { data } = await this.$http.get('/api/v2/myproject/check_publish', {
      params: {
        projectUuid,
      },
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async publishProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/set_project_status/publish', {
      projectUuid,
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async checkDeleteProject({}, projectUuid) {
    const { data } = await this.$http.get('/api/v2/myproject/check_delete_project', {
      params: {
        projectUuid,
      },
    });
    return data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteProject({}, projectUuid) {
    const { data } = await this.$http.post('/api/v2/myproject/set_project_status/delete', {
      projectUuid,
    });
    return data.data;
  },
};
