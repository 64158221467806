export const state = () => ({
  districts: [],
  district: null,
  villages: [],
  village: null,

  openVillage: false,
});

export const getters = {
  formData(state) {
    return {
      district_id: state.district ? state.district.id : state.district,
      village_id: state.village ? state.village.id : state.village,
    };
  },
};

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.district = null;
    state.village = null;
    state.districts = [];
    state.villages = [];
    state.openVillage = false;
  },
  SET_REGIONAL_DATA(state, payload) {
    state.village = payload.village;
    state.villages = payload.villages;
    state.districts = payload.districts;
    state.district = payload.district;
  },
  SET_OPEN_VILLAGE(state, payload) {
    state.openVillage = payload;
  },
  SET_DISTRICT(state, payload) {
    state.district = payload;
  },
  SET_VILLAGE(state, payload) {
    state.village = payload;
  },
  SET_CITIES(state, payload) {
    state.districts = payload;
  },
  SET_VILLAGES(state, payload) {
    state.villages = payload;
  },
};

export const actions = {
  changeDistrict({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_DISTRICT', payload);
      } else {
        const dId = payload;
        let selectedDistrict = state.districts.find(function (item) {
          return item.id == dId;
        });
        if (!selectedDistrict) {
          selectedDistrict = null;
        }
        commit('SET_DISTRICT', selectedDistrict);
      }
      commit('SET_OPEN_VILLAGE', true);
    } else {
      commit('SET_DISTRICT', null);
    }
  },
  changeVillage({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_VILLAGE', payload);
      } else {
        const vId = payload;
        let selectedVillage = state.villages.find(function (item) {
          return item.id == vId;
        });
        if (!selectedVillage) {
          selectedVillage = null;
        }
        commit('SET_VILLAGE', selectedVillage);
      }
    } else {
      commit('SET_VILLAGE', null);
    }
  },

  async setRegionalData({ commit, dispatch }, requestData) {
    if (!requestData) {
      await commit('SET_VILLAGES', []);
      dispatch('changeDistrict', null);
      dispatch('changeVillage', null);
    } else {
      await commit('SET_CITIES', requestData.districts);
      await commit('SET_VILLAGES', requestData.villages);
      dispatch('changeDistrict', requestData.district);
      dispatch('changeVillage', requestData.village);
    }
  },

  async getVillages({ state, commit, dispatch }) {
    commit('SET_VILLAGES', []);
    dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getVillageByDistrict', {
        district_id: state.district.id,
      })
      .then((result) => {
        commit('SET_VILLAGES', result.data);
      });
  },
};
