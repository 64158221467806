export const state = () => ({
  loading: false,
  projects: [],
  isFetchingProject: true,
  selectedListingType: {
    id: 2,
    name: 'Properti Dijual',
  },
});

export const getters = {
  listingType(state, getters, rootState) {
    return state.selectedListingType.id === 1
      ? rootState.v2.masters.listingTypeEnum.PRIMARY
      : rootState.v2.masters.listingTypeEnum.SECONDARY;
  },
};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PROJECTS(state, payload) {
    state.projects = payload;
  },
  SET_SELECTED_LISTING_TYPE(state, payload) {
    state.selectedListingType = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.loading = false;
    state.projects = [];
    state.selectedListingType = {
      id: 2,
      name: 'Properti Dijual',
    };
  },
};

export const actions = {
  async getMyShortlists({ commit }, { listingType }) {
    try {
      commit('SET_LOADING', true);
      await this.$http.get('api/shortlist/get_shortlist?type=' + listingType).then((response) => {
        commit('SET_PROJECTS', response.data.data);
      });
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
